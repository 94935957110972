import React from 'react';
import styled from 'styled-components';

import { selectGameURL } from 'app/src/selectors/game';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import RealTimeCounter from 'app/src/components/ui/RealTimeCounter';
import { isMobile } from 'shared/vars';
import _, { getReleaseStatusCopy } from 'shared/copy';
import Tooltip from 'shared/components/Tooltip';
import { useSelectUser } from 'app/src/selectors/user';

const Container = styled.div`
	background: ${props => props.theme.pureWhite};
	position: relative;
	box-shadow: ${props => props.theme.boxShadowSmall};
	border-radius: 8px;
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;

	${isMobile && `
	height: auto;
	align-items: center;
	padding: 8px;
	`}
`;

const Thumbnail = styled.div`
	position: relative;
	border-radius: 8px;
	background: ${props => (props.thumbnailUrl ? `url("${props.thumbnailUrl}")` : props.theme.grey7)};
	background-size: cover;
	background-position: center center;
	height: 64px;
	width: 64px;
	margin: 24px;
	flex-shrink: 0;

	${isMobile && `
	margin: 12px 0 12px 12px;
	`}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;

	${isMobile && `
	padding: 0 12px 0 16px;
	`}
`;

const Title = styled.div`
	font-family: Torus;
	font-size: 21px;
	font-weight: bold;
	color: ${props => props.theme.denimBlue};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	${isMobile && `
	font-size: 18px;
	line-height: 20px;
	`}
`;

const StyledTooltip = styled(Tooltip)`
	border-bottom: 2px dotted ${props => props.theme.grey5};
`;

const Subtitle = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
`;

const URL = styled.a`
	font-size: 16px;
	font-weight: normal;
	text-overflow: ellipsis;
	overflow: hidden;

	${isMobile && `
	font-size: 14px;
	`}
`;

const SymbolContainer = styled.div`
	position: absolute;
	right: 0;
	width: 300px;
	height: 300px;
	top: 50%;
	transform: translate(40%, -30%)
`;

const HeroGame = props => {
	const { game, renderCounter, number, symbols, symbol: Symbol } = props;

	const user = useSelectUser();
	const isAdmin = user.role === 'admin';

	const { title, thumbnail_url: thumbnailUrl } = game || {};
	const { url, isPreview } = selectGameURL(game) || {};

	let urlText = url;
	if (isPreview) {
		urlText = `${urlText.slice(0, 36)}...`;
	}

	const developerReleaseStatus = _([getReleaseStatusCopy(game?.content_metadata?.release_status, false, false, game?.approved)]);
	const realReleaseStatus = _`realStatus${{ status: _([game?.content_metadata?.release_status || 'not-released']) }}`;

	return (
		<Container>
			<Thumbnail thumbnailUrl={getGameThumbnailUrl(thumbnailUrl, 64)} />
			<Info>
				<Title>{title}</Title>
				<Subtitle>
					{isAdmin ? (
						<StyledTooltip
							placement="top"
							arrow
							content={realReleaseStatus}
						>
							{developerReleaseStatus}
						</StyledTooltip>
					) : (
						developerReleaseStatus
					)}
				</Subtitle>
				{url && (
					<URL href={url} target="_blank">{urlText}</URL>
				)}
			</Info>
			{!isMobile && (
				<>
					{renderCounter && <RealTimeCounter key={game.id} colorNumber={number % symbols.length} game={game} />}
					<SymbolContainer>
						{Symbol && <Symbol />}
					</SymbolContainer>
				</>
			)}
		</Container>
	);
};

export default HeroGame;
