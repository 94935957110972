// isReleasedForDeveloper returns true if the game is considered released for the developer.
//
// 10k-test games are not considered live games for the developer.
export function isReleasedForDeveloper(game) {
	if (!game) {
		return false;
	}

	return game?.content_metadata?.release_status && ![
		'not-released',
		'no-link-release',
		'10k-test',
		'10k-test-with-content-restrictions',
	].includes(game?.content_metadata?.release_status);
}

// isReleased returns true if the game is considered released and should have things like
// revenue shares and exclusivity set correctly.
export function isReleased(game) {
	if (!game) {
		return false;
	}

	return game?.content_metadata?.release_status && ![
		'not-released',
		'no-link-release',
		'10k-test',
		'10k-test-with-content-restrictions',
		// Technical test are not considered released and don't require a revshare for example.
		'technical-test',
		'technical-test-with-content-restrictions',
	].includes(game?.content_metadata?.release_status);
}

// isApprovedOrLive returns true if the game is approved or has a 10k-test or no-link-release status.
export function isApprovedOrLive(game) {
	if (!game) {
		return false;
	}
	if (game.approved) {
		return true;
	}

	return [
		'no-link-release',
		'10k-test',
		'10k-test-with-content-restrictions',
	].includes(game?.content_metadata?.release_status);
}
