import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Subject, tap, map } from 'rxjs';
import { Controller, useForm } from 'react-hook-form';
import { useSelectApiStatus } from '@poki/rx-api';
import { yupResolver } from '@hookform/resolvers/yup';
import history from 'app/history';
import * as yup from 'yup';

import { registerModal } from 'app/src/modals';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { useSelectExternalFileURL, useSelectPreviouslySelectedGameId } from 'app/src/selectors/session';
import { useSelectGame, useSelectGamesByTeamId } from 'app/src/selectors/game';
import { getGameById, createGameVersion, listGamesByTeamId } from 'app/src/epics/game';
import { openModal } from 'app/src/actions/client';
import { useSelectUser } from 'app/src/selectors/user';
import { clearExternalFileURL, setExternalFileUpload, setPreviouslySelectedGameId } from 'app/src/actions/session';
import getExternalFileByURL from 'app/src/utils/getExternalFileByURL';
import prettyFileSize from 'app/src/utils/prettyFileSize';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';

import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import Button from 'app/src/components/ui/Button';
import ProgressBar from 'app/src/components/ui/ProgressBar';
import FileInput from 'app/src/components/input/FileInput';
import TextInput from 'app/src/components/input/TextInput';
import CheckBoxInput from 'app/src/components/input/CheckBoxInput';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import SlideOut from 'app/src/components/ui/SlideOut';
import WarningMessage from 'app/src/components/ui/WarningMessage';
import ReactSelectInput from 'app/src/components/input/ReactSelectInput';
import LinkStyledButton from 'app/src/components/ui/LinkStyledButton';

import _ from 'shared/copy';

const StyledModal = styled(Modal)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Form = styled.form`
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`;

const UploadText = styled.div`
	font-weight: bold;
	margin-bottom: 10px;
`;

const UploadProgressBar = styled(ProgressBar)`
	width: 100%;
	transition: opacity 0.2s ease-out;

	${props => props.inactive && `
	opacity: 0.1;
	`}
`;

const Buttons = styled.div`
	margin-top: auto;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const StyledFileInput = styled(FileInput)`
	${props => (props.$hidden ? `
	display: none;
	` : '')}
`;

const OptimizeImagesWarning = styled(WarningMessage)`
	margin-top: -10px;
`;

const EmptyThumbnail = styled.div`
	width: 24px;
	height: 24px;
	background-color: ${props => props.theme.grey7};
	border-radius: 4px;
`;

const createValidationSchema = isExternal => (
	yup.object().shape({
		file: yup.mixed().required(_`fieldRequired`),
		name: yup.string().required(_`fieldRequired`),
		game: isExternal
			? yup.string().required(_`fieldRequired`)
			: yup.string(),
		notes: yup.string().required(_`fieldRequired`),
	})
);

const getGameSelectOptions = games => games.map(game => ({
	value: game.id,
	label: game.title,
	icon: getGameThumbnailUrl(game.thumbnail_url) || <EmptyThumbnail />,
}));

const GameCreateVersionModal = props => {
	const { data: { gameId, isExternal = false } } = props;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const user = useSelectUser();
	const game = useSelectGame(gameId);
	const activeTeam = useSelectActiveTeam();
	const externalFileURL = useSelectExternalFileURL();
	const { data: games = [] } = useSelectGamesByTeamId(activeTeam?.id);
	const previouslySelectedGameId = useSelectPreviouslySelectedGameId();

	const [optimizeImages, setOptimizeImages] = useState(true);
	const [isUploading, setIsUploading] = useState();
	const [exit$] = useState(new Subject());

	const createGameVersionStatus = useSelectApiStatus(createGameVersion.id);

	const validationSchema = createValidationSchema(isExternal);

	const { handleSubmit, control, getValues, watch, setError, setValue, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			file: '',
			game: previouslySelectedGameId || '',
			name: '',
			notes: '',
			optimize_images: true,
		},
		resolver: yupResolver(validationSchema),
	});

	const fetchExternalFile = useCallback(({ url }) => {
		getExternalFileByURL(url, file => {
			// Custom behavior for setting the file input value
			setValue('file', file, { shouldValidate: true });
			dispatch(setExternalFileUpload({ isOpen: true }));
		}, err => {
			setError('file', { message: err.message });
			dispatch(setExternalFileUpload({ isOpen: true }));
		});
	}, [setValue]);

	const handleAddGame = () => {
		if (activeTeam.verified && user.role === 'admin') {
			dispatch(openModal({ key: 'create-game' }));
		} else {
			history.push(`/${activeTeam.code}/games/create`);
		}
	};

	const getOptions = () => {
		const addGameOption = {
			value: 'add-new-game',
			label: (
				<LinkStyledButton onClick={handleAddGame}>
					{_`addGame`}
				</LinkStyledButton>
			),
			icon: <PlusIcon />,
			isDisabled: true,
		};

		return [addGameOption].concat(getGameSelectOptions(games));
	};

	const fileWatch = watch('file');

	useEffect(() => {
		if (!gameId) {
			return;
		}

		dispatch(getGameById.fetch(gameId));
	}, []);

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
	}, [activeTeam.id]);

	useEffect(() => {
		const { error } = createGameVersionStatus;

		if (error) {
			setIsUploading(false);

			const err = {
				error: error.response?.error || _`unknown`,
				message: error.response?.message || _`unknown`,
			};

			setError('file', { message: err.message });

			dispatch(startScreenShake());
		}
	}, [createGameVersionStatus]);

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	useEffect(() => {
		if (isExternal && externalFileURL) {
			fetchExternalFile({ url: externalFileURL });
		}
	}, [externalFileURL, fetchExternalFile]);

	const onSubmit = data => {
		const { file, name, notes, game: selectedGame } = data;

		const formData = new FormData();
		formData.append('file', file);
		formData.append('label', name);
		formData.append('notes', notes);
		formData.append('disable-image-compression', !optimizeImages ? 'true' : 'false');

		setIsUploading(true);

		dispatch(
			createGameVersion.fetch({ gameId: gameId ?? selectedGame, formData }, ({ success$: _success$ }) => (
				_success$.pipe(
					tap(() => {
						exit$.next();

						if (externalFileURL) {
							dispatch(clearExternalFileURL());
							navigate(`/${activeTeam.code}/games/${selectedGame}/versions`);
						}
					}),
					map(() => getGameById.fetch(selectedGame)),
				)
			)),
		);
	};

	const { progress, progressEvent } = createGameVersionStatus;

	if (!game && !externalFileURL) return null;

	const submitDisabled = isUploading || !fileWatch;

	return (
		<StyledModal exit$={exit$} canExit={source => !isUploading || source === EXIT_SOURCES.SUBJECT}>
			<h2>{_`createVersion`}</h2>
			<Form onSubmit={handleSubmit(onSubmit)}>
				{isUploading && (
					progressEvent && (
						<>
							<UploadText>
								{_`uploading`} {prettyFileSize(progressEvent.loaded)} / {prettyFileSize(progressEvent.total)}
							</UploadText>
							<UploadProgressBar progress={progress} inactive={progress >= 1} />
						</>
					)
				)}
				<Controller
					control={control}
					name="file"
					render={({ field: { onChange, value } }) => (
						<StyledFileInput
							label={_`uploadAZipOrFolder`}
							accept="application/zip, application/x-zip-compressed"
							webkitdirectory=""
							directory=""
							multiple
							required
							value={value}
							valueSetter={onChange}
							$hidden={isUploading}
							errors={errors?.file?.message ? [errors?.file?.message] : []}
						/>
					)}
				/>
				{!isUploading && (
					<>
						{isExternal && (
							<Controller
								control={control}
								name="game"
								render={({ field: { onChange, value } }) => {
									const handleChange = selectedOption => {
										onChange(selectedOption);
										dispatch(setPreviouslySelectedGameId({ gameId: selectedOption }));
									};

									return (
										<ReactSelectInput
											required
											options={getOptions()}
											label={_`uploadToGame`}
											placeholder={_`selectAGame`}
											value={value}
											onChange={handleChange}
											errors={errors?.game?.message ? [errors?.game?.message] : []}
										/>
									);
								}}
							/>
						)}
						<Controller
							control={control}
							name="name"
							render={({ field: { onChange, value } }) => (
								<TextInput
									label={_`name`}
									required
									value={value}
									valueSetter={onChange}
									placeholder={getValues('version_file')?.name || _`setACustomLabel`}
									errors={errors?.name?.message ? [errors?.name?.message] : []}
								/>
							)}
						/>
						<Controller
							control={control}
							name="notes"
							render={({ field: { onChange, value } }) => (
								<TextAreaInput
									label={_`versionNotes`}
									required
									value={value}
									valueSetter={onChange}
									placeholder={_`versionNotesPlaceholder`}
									errors={errors?.notes?.message ? [errors?.notes?.message] : []}
								/>
							)}
						/>
						<SlideOut
							title={_`advancedOptions`}
						>
							<CheckBoxInput
								text={_`optimizeImages`}
								name="optimize_images"
								value={optimizeImages}
								valueSetter={setOptimizeImages}
								description={_`optimizeImagesDescription`}
								isBox
							/>
							{!optimizeImages && (
								<OptimizeImagesWarning message={_`optimizeImagesWarning`} />
							)}
						</SlideOut>
					</>
				)}
				<Buttons>
					<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
					<Button submit disabled={submitDisabled || !isDirty}>
						{isUploading ? _`uploading` : _`upload`}
					</Button>
				</Buttons>
			</Form>
		</StyledModal>
	);
};

registerModal('create-game-version', GameCreateVersionModal);
