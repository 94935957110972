import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { patchGame, archiveVersion, unarchiveVersion, patchGameVersion, createGameVersion, gameSetVersionActive } from 'app/src/epics/game';
import { useSelectIsPersistedHiddenMessageBox } from 'app/src/selectors/session';
import { cancelReviewRequest, createReviewRequest } from 'app/src/epics/review';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import useActionCounter from 'app/src/hooks/useActionCounter';
import { isApprovedOrLive } from 'app/src/utils/game';

import ArchivedVersionsTable from 'app/src/components/tables/versions/ArchivedVersionsTable';
import ActiveVersionTable from 'app/src/components/tables/versions/ActiveVersionTable';
import NonArchivedVersionsTable from 'app/src/components/tables/versions/NonArchivedVersionsTable';
import GridContainer from 'app/src/components/ui/GridContainer';
import Card from 'app/src/components/ui/Card';

import { smallMedia } from 'shared/vars';

const StyledDescriptionContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 40px;
	align-self: stretch;
	flex-wrap: wrap;

	${smallMedia} {
		flex-wrap: nowrap;
	}
`;

const StyledDescriptionWrapper = styled.div`
	display: flex;
	padding-bottom: 8px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
	min-width: 100%;

	${smallMedia} {
		min-width: auto;
	}
`;

const StyledDescriptionWrapperRight = styled.div`
	display: flex;
	padding-bottom: 8px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
	padding: 24px;
	border-radius: 8px;
	background: ${props => props.theme.grey7};
`;

const StyledDescription = styled.span`
	font: 400 18px/30px "Proxima Nova",Arial;
`;

const StyledDescriptionRight = styled.div`
	color: ${props => props.theme.pokiBlue};
	font: 700 16px/24px "Proxima Nova",Arial;
`;

const StyledList = styled.ul`
	font: 400 16px/24px "Proxima Nova";
	margin: 0;
	padding: 0 16px;
`;

const GameVersionsSubPage = props => {
	const { game } = props;

	const activeTeam = useSelectActiveTeam();

	const [hasAnyLabelFocus, setHasAnyLabelFocus] = useState(false); // Used to prevent fetching inactive versions when a label is focused

	const actionCounter = useActionCounter(
		cancelReviewRequest.success.type,
		patchGame.success.type,
		archiveVersion.success.type,
		unarchiveVersion.success.type,
		patchGameVersion.success.type,
		createReviewRequest.success.type,
		createGameVersion.success.type,
		gameSetVersionActive.success.type,
	);

	const whatYouGetId = 'what-you-get-with-versions';

	const hidingWhatYouGetCard = useSelectIsPersistedHiddenMessageBox(whatYouGetId);

	const showActiveVersions = isApprovedOrLive(game);

	return (
		<>
			<Helmet key={`GameVersionsSubPage-${game.id}-${activeTeam.code}`}>
				<title>Versions - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>

			<GridContainer cols={1}>
				{!hidingWhatYouGetCard && (
					<Card
						title="What you get with Versions"
						id={whatYouGetId}
						closeable
					>
						<StyledDescriptionContainer>
							<StyledDescriptionWrapper>
								<StyledDescription>
									Poki offers free hosting for your web game. This is the start of testing your game on web and getting player feedback.
								</StyledDescription>
							</StyledDescriptionWrapper>
							<StyledDescriptionWrapperRight>
								<div>
									<StyledDescriptionRight>
										Upload your game
									</StyledDescriptionRight>
									<StyledList>
										<li>Needs to be a .zip file.</li>
										<li>Should have an index.html file.</li>
									</StyledList>
								</div>
								<div>
									<StyledDescriptionRight>
										Share your game
									</StyledDescriptionRight>
									A preview link to your game is at the top of the page. It will always be your last uploaded build and you can share this link with family, friends, and fans.
								</div>
							</StyledDescriptionWrapperRight>
						</StyledDescriptionContainer>
					</Card>
				)}
				{showActiveVersions && (
					<ActiveVersionTable
						game={game}
						actionCounter={actionCounter}
						setHasAnyLabelFocus={setHasAnyLabelFocus}
					/>
				)}
				<NonArchivedVersionsTable
					game={game}
					actionCounter={actionCounter}
					hasAnyLabelFocus={hasAnyLabelFocus}
					setHasAnyLabelFocus={setHasAnyLabelFocus}
					excludeActiveVersions={showActiveVersions}
				/>
				<ArchivedVersionsTable
					game={game}
					actionCounter={actionCounter}
				/>
			</GridContainer>
		</>
	);
};

export default GameVersionsSubPage;
