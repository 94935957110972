import { getPromptsList, testPrompt } from 'app/src/epics/guardian';

const initialState = {
	prompts: [],
	testResult: {},
};

export default function guardianReducer(state = initialState, action) {
	if (action.type === getPromptsList.success.type) {
		const { response } = action.payload.result;

		return {
			...state,
			prompts: response || [],
		};
	}

	if (action.type === testPrompt.success.type) {
		const { response } = action.payload.result;

		return {
			...state,
			testResult: response || {},
		};
	}

	return state;
}
