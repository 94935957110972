import React, { useEffect, useState } from 'react';
import { useSelectApiStatus } from '@poki/rx-api';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import { getPlayerFitTestsByGameId } from 'app/src/epics/playerfit';
import { useSelectPlayerFitTestsByGameId } from 'app/src/selectors/playerfit';
import { getGameById, listGamesByTeamId } from 'app/src/epics/game';
import { useSelectGameVersionsForPlaytests } from 'app/src/selectors/game';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { openModal } from 'app/src/actions/client';
import { useSelectPermissions } from 'app/src/selectors/user';
import getPlaytimeRange from 'app/src/utils/getPlaytimeRange';
import getVersionLabel from 'app/src/utils/getVersionLabel';
import checkPermissions from 'app/src/utils/checkPermissions';
import formatTime from 'app/src/utils/formatTime';

import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import GridContainer from 'app/src/components/ui/GridContainer';
import Table from 'app/src/components/ui/Table';
import Card from 'app/src/components/ui/Card';
import Tooltip from 'shared/components/Tooltip';

import { dayMonthTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const PlayerFitTestPlayed = styled.div`
	color: ${props => props.theme.grey1};
`;

const UPDATE_INTERVAL = 30;

const GamePlayerFitTestSubPage = props => {
	const { game } = props;

	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();
	const versions = useSelectGameVersionsForPlaytests(game.id);
	const permissions = useSelectPermissions();

	const [page, setPage] = useState(1);

	const getPlayerFitTestsByGameIdStatus = useSelectApiStatus(getPlayerFitTestsByGameId.id);
	const { data: playerFitTests } = useSelectPlayerFitTestsByGameId(game.id);

	const tableLoadingDone = (playerFitTests && playerFitTests.length > 0) || getPlayerFitTestsByGameIdStatus.done;

	// Fetch a list of recordings when the page opens.
	useEffect(() => {
		dispatch(getPlayerFitTestsByGameId.fetch({ gameId: game.id }));

		const i = setInterval(() => {
			dispatch(getPlayerFitTestsByGameId.fetch({ gameId: game.id }));
			dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
			dispatch(getGameById.fetch(game.id));
		}, UPDATE_INTERVAL * 1000);

		return () => {
			clearInterval(i);
		};
	}, []);

	const onSuccessCallback = () => {
		dispatch(getGameById.fetch(game.id));
	};

	const handleStartNewPlayerFitTest = () => {
		const { id, approved, content_metadata: { release_status } = {} } = game;

		dispatch(openModal({
			key: 'start-new-playerfit-test',
			data: { gameId: id, gameApproved: approved, releaseStatus: release_status, onSuccessCallback },
		}));
	};

	const canRequestRecordings = checkPermissions(permissions, [['can_edit_all_player_fit_tests', 'can_edit_owned_player_fit_tests']]);

	return (
		<>
			<Helmet key={`GamePlayerFitTestSubPage-${game.id}-${activeTeam.code}`}>
				<title>Player Fit Tests - {game.title} - {activeTeam.name} - Poki for Developers</title>
			</Helmet>
			<GridContainer cols={1}>
				<Card
					title="Player Fit Tests"
					noPadding
					elevated
					buttons={[
						...((canRequestRecordings && [
							{
								id: 'start-new-playerfit-test',
								type: 'button',
								icon: PlusIcon,
								action: handleStartNewPlayerFitTest,
								disabled: versions.length === 0,
								children: 'New Player Fit Test',
							},
						]) || []),
					]}
				>
					<Table
						title="PlayerFitTests"
						isLoading={!tableLoadingDone}
						items={playerFitTests || []}
						setPagination={setPage}
						page={page}
						perPage={20}
						totalItems={(playerFitTests || []).length}
						autoPaging
						columns={[
							{
								title: _`when`,
								content: ({ item }) => (
									<PlayerFitTestPlayed>
										{moment(item.created_at * 1000).format(dayMonthTimeFormat)}
									</PlayerFitTestPlayed>
								),
							},
							{
								title: 'Status',
								content: ({ item }) => (
									<Tooltip
										arrow
										placement="top-end"
										maxWidth="250px"
										content={`In ${formatTime((item.stopped_at || (Date.now() / 1000)) - item.created_at, true, true)}`}
									>
										{item.stopped_at ? 'completed' : 'in progress'}
									</Tooltip>
								),
							},
							{
								title: _`version`,
								content: ({ item }) => {
									const version = game.versions.find(v => v.id === item.version_id);

									return (
										version ? (
											getVersionLabel(version)
										) : (
											'Unknown'
										)
									);
								},
							},
							{
								title: 'Gameplays',
								content: ({ item }) => item.gameplays,
							},
							{
								title: 'Playtime range',
								content: ({ item }) => (item.gameplays > 0 ? getPlaytimeRange(item) : '-'),
							},
						]}
					/>
				</Card>
			</GridContainer>
		</>
	);
};

export default GamePlayerFitTestSubPage;
