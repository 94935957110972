export const guardianLanguages = [
	{ value: '', desc: 'Any' },
	{ value: 'AB', desc: 'Abkhazian' },
	{ value: 'AA', desc: 'Afar' },
	{ value: 'AF', desc: 'Afrikaans' },
	{ value: 'AK', desc: 'Akan' },
	{ value: 'SQ', desc: 'Albanian' },
	{ value: 'AM', desc: 'Amharic' },
	{ value: 'AR', desc: 'Arabic' },
	{ value: 'AN', desc: 'Aragonese' },
	{ value: 'HY', desc: 'Armenian' },
	{ value: 'AS', desc: 'Assamese' },
	{ value: 'AV', desc: 'Avaric' },
	{ value: 'AE', desc: 'Avestan' },
	{ value: 'AY', desc: 'Aymara' },
	{ value: 'AZ', desc: 'Azerbaijani' },
	{ value: 'BM', desc: 'Bambara' },
	{ value: 'BA', desc: 'Bashkir' },
	{ value: 'EU', desc: 'Basque' },
	{ value: 'BE', desc: 'Belarusian' },
	{ value: 'BN', desc: 'Bengali' },
	{ value: 'BH', desc: 'Bihari' },
	{ value: 'BI', desc: 'Bislama' },
	{ value: 'BS', desc: 'Bosnian' },
	{ value: 'BR', desc: 'Breton' },
	{ value: 'BG', desc: 'Bulgarian' },
	{ value: 'MY', desc: 'Burmese' },
	{ value: 'CA', desc: 'Catalan' },
	{ value: 'CH', desc: 'Chamorro' },
	{ value: 'CE', desc: 'Chechen' },
	{ value: 'NY', desc: 'Chichewa' },
	{ value: 'ZH', desc: 'Chinese' },
	{ value: 'CU', desc: 'Church Slavic' },
	{ value: 'CV', desc: 'Chuvash' },
	{ value: 'KW', desc: 'Cornish' },
	{ value: 'CO', desc: 'Corsican' },
	{ value: 'CR', desc: 'Cree' },
	{ value: 'HR', desc: 'Croatian' },
	{ value: 'CS', desc: 'Czech' },
	{ value: 'DA', desc: 'Danish' },
	{ value: 'DV', desc: 'Divehi' },
	{ value: 'NL', desc: 'Dutch' },
	{ value: 'DZ', desc: 'Dzongkha' },
	{ value: 'EN', desc: 'English' },
	{ value: 'EO', desc: 'Esperanto' },
	{ value: 'ET', desc: 'Estonian' },
	{ value: 'EE', desc: 'Ewe' },
	{ value: 'FO', desc: 'Faroese' },
	{ value: 'FJ', desc: 'Fijian' },
	{ value: 'FI', desc: 'Finnish' },
	{ value: 'FR', desc: 'French' },
	{ value: 'FF', desc: 'Fulah' },
	{ value: 'GL', desc: 'Galician' },
	{ value: 'LG', desc: 'Ganda' },
	{ value: 'KA', desc: 'Georgian' },
	{ value: 'DE', desc: 'German' },
	{ value: 'EL', desc: 'Greek' },
	{ value: 'GN', desc: 'Guarani' },
	{ value: 'GU', desc: 'Gujarati' },
	{ value: 'HT', desc: 'Haitian' },
	{ value: 'HA', desc: 'Hausa' },
	{ value: 'HE', desc: 'Hebrew' },
	{ value: 'HZ', desc: 'Herero' },
	{ value: 'HI', desc: 'Hindi' },
	{ value: 'HO', desc: 'Hiri Motu' },
	{ value: 'HU', desc: 'Hungarian' },
	{ value: 'IS', desc: 'Icelandic' },
	{ value: 'IO', desc: 'Ido' },
	{ value: 'IG', desc: 'Igbo' },
	{ value: 'ID', desc: 'Indonesian' },
	{ value: 'IA', desc: 'Interlingua' },
	{ value: 'IE', desc: 'Interlingue' },
	{ value: 'IU', desc: 'Inuktitut' },
	{ value: 'IK', desc: 'Inupiaq' },
	{ value: 'GA', desc: 'Irish' },
	{ value: 'IT', desc: 'Italian' },
	{ value: 'JA', desc: 'Japanese' },
	{ value: 'JV', desc: 'Javanese' },
	{ value: 'KL', desc: 'Kalaallisut' },
	{ value: 'KN', desc: 'Kannada' },
	{ value: 'KR', desc: 'Kanuri' },
	{ value: 'KS', desc: 'Kashmiri' },
	{ value: 'KK', desc: 'Kazakh' },
	{ value: 'KM', desc: 'Khmer' },
	{ value: 'KI', desc: 'Kikuyu' },
	{ value: 'RW', desc: 'Kinyarwanda' },
	{ value: 'RN', desc: 'Kirundi' },
	{ value: 'KV', desc: 'Komi' },
	{ value: 'KG', desc: 'Kongo' },
	{ value: 'KO', desc: 'Korean' },
	{ value: 'KU', desc: 'Kurdish' },
	{ value: 'KJ', desc: 'Kwanyama' },
	{ value: 'KY', desc: 'Kyrgyz' },
	{ value: 'LO', desc: 'Lao' },
	{ value: 'LA', desc: 'Latin' },
	{ value: 'LV', desc: 'Latvian' },
	{ value: 'LI', desc: 'Limburgish' },
	{ value: 'LN', desc: 'Lingala' },
	{ value: 'LT', desc: 'Lithuanian' },
	{ value: 'LU', desc: 'Luba-Katanga' },
	{ value: 'LB', desc: 'Luxembourgish' },
	{ value: 'MK', desc: 'Macedonian' },
	{ value: 'MG', desc: 'Malagasy' },
	{ value: 'MS', desc: 'Malay' },
	{ value: 'ML', desc: 'Malayalam' },
	{ value: 'MT', desc: 'Maltese' },
	{ value: 'GV', desc: 'Manx' },
	{ value: 'MI', desc: 'Maori' },
	{ value: 'MR', desc: 'Marathi' },
	{ value: 'MH', desc: 'Marshallese' },
	{ value: 'MN', desc: 'Mongolian' },
	{ value: 'NA', desc: 'Nauru' },
	{ value: 'NV', desc: 'Navajo' },
	{ value: 'NG', desc: 'Ndonga' },
	{ value: 'NE', desc: 'Nepali' },
	{ value: 'ND', desc: 'North Ndebele' },
	{ value: 'SE', desc: 'Northern Sami' },
	{ value: 'NO', desc: 'Norwegian' },
	{ value: 'NB', desc: 'Norwegian Bokmål' },
	{ value: 'NN', desc: 'Norwegian Nynorsk' },
	{ value: 'II', desc: 'Nuosu' },
	{ value: 'OC', desc: 'Occitan' },
	{ value: 'OJ', desc: 'Ojibwa' },
	{ value: 'OR', desc: 'Oriya' },
	{ value: 'OM', desc: 'Oromo' },
	{ value: 'OS', desc: 'Ossetian' },
	{ value: 'PI', desc: 'Pali' },
	{ value: 'PS', desc: 'Pashto' },
	{ value: 'FA', desc: 'Persian' },
	{ value: 'PL', desc: 'Polish' },
	{ value: 'PT', desc: 'Portuguese' },
	{ value: 'PA', desc: 'Punjabi' },
	{ value: 'QU', desc: 'Quechua' },
	{ value: 'RO', desc: 'Romanian' },
	{ value: 'RM', desc: 'Romansh' },
	{ value: 'RU', desc: 'Russian' },
	{ value: 'SM', desc: 'Samoan' },
	{ value: 'SG', desc: 'Sango' },
	{ value: 'SA', desc: 'Sanskrit' },
	{ value: 'SC', desc: 'Sardinian' },
	{ value: 'GD', desc: 'Scottish Gaelic' },
	{ value: 'SR', desc: 'Serbian' },
	{ value: 'SN', desc: 'Shona' },
	{ value: 'SD', desc: 'Sindhi' },
	{ value: 'SI', desc: 'Sinhala' },
	{ value: 'SK', desc: 'Slovak' },
	{ value: 'SL', desc: 'Slovenian' },
	{ value: 'SO', desc: 'Somali' },
	{ value: 'NR', desc: 'South Ndebele' },
	{ value: 'ST', desc: 'Southern Sotho' },
	{ value: 'ES', desc: 'Spanish' },
	{ value: 'SU', desc: 'Sundanese' },
	{ value: 'SW', desc: 'Swahili' },
	{ value: 'SS', desc: 'Swati' },
	{ value: 'SV', desc: 'Swedish' },
	{ value: 'TL', desc: 'Tagalog' },
	{ value: 'TY', desc: 'Tahitian' },
	{ value: 'TG', desc: 'Tajik' },
	{ value: 'TA', desc: 'Tamil' },
	{ value: 'TT', desc: 'Tatar' },
	{ value: 'TE', desc: 'Telugu' },
	{ value: 'TH', desc: 'Thai' },
	{ value: 'BO', desc: 'Tibetan' },
	{ value: 'TI', desc: 'Tigrinya' },
	{ value: 'TO', desc: 'Tonga' },
	{ value: 'TS', desc: 'Tsonga' },
	{ value: 'TN', desc: 'Tswana' },
	{ value: 'TR', desc: 'Turkish' },
	{ value: 'TK', desc: 'Turkmen' },
	{ value: 'TW', desc: 'Twi' },
	{ value: 'UG', desc: 'Uighur' },
	{ value: 'UK', desc: 'Ukrainian' },
	{ value: 'XX', desc: 'Unknown' },
	{ value: 'UR', desc: 'Urdu' },
	{ value: 'UZ', desc: 'Uzbek' },
	{ value: 'VE', desc: 'Venda' },
	{ value: 'VI', desc: 'Vietnamese' },
	{ value: 'VO', desc: 'Volapük' },
	{ value: 'WA', desc: 'Walloon' },
	{ value: 'CY', desc: 'Welsh' },
	{ value: 'FY', desc: 'Western Frisian' },
	{ value: 'WO', desc: 'Wolof' },
	{ value: 'XH', desc: 'Xhosa' },
	{ value: 'YI', desc: 'Yiddish' },
	{ value: 'YO', desc: 'Yoruba' },
	{ value: 'ZA', desc: 'Zhuang' },
	{ value: 'ZU', desc: 'Zulu' },
];
