import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSelectGameChecklistStatus } from 'app/src/selectors/session';
import { useSelectActiveTeam } from 'app/src/selectors/team';

import ChecklistCard from 'app/src/components/ui/ChecklistCard';
import Level1CreateGame from 'app/src/components/pages/game/checklist/Level1CreateGame';
import PlaytestStep1 from 'app/src/components/pages/game/checklist/PlaytestStep1';
import PlaytestStep2 from 'app/src/components/pages/game/checklist/PlaytestStep2';
import PlaytestStep3 from 'app/src/components/pages/game/checklist/PlaytestStep3';
import PlayerFitTest from 'app/src/components/pages/game/checklist/PlayerFitTest';
import WebFitStep1 from 'app/src/components/pages/game/checklist/WebFitStep1';
import WebFitStep2 from 'app/src/components/pages/game/checklist/WebFitStep2';
import WebFitStep3 from 'app/src/components/pages/game/checklist/WebFitStep3';
import Level4Description from 'app/src/components/pages/game/checklist/Level4Description';

import { isSelfServiceStageAfter } from 'app/src/utils/isSelfServiceStageAfter';

import _ from 'shared/copy';

const ChecklistWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;
`;

const GameOverviewChecklistModule = ({ game = {} }) => {
	const [activeLevel, setActiveLevel] = useState(0);
	const [current, setCurrent] = useState(0);

	const checklistStatus = useSelectGameChecklistStatus(game?.id);
	const activeTeam = useSelectActiveTeam();

	const isGameJam = activeTeam?.customer_segment === 'game-jam';
	const isTeamVerified = activeTeam?.verified;

	const isPlaytestCompleted = checklistStatus?.playtest?.[2]?.status === 'completed' || isSelfServiceStageAfter(game?.self_service_stage, 'running_playtests');
	const isPlayerFitCompleted = checklistStatus?.playerfit?.[0]?.status === 'completed' || isSelfServiceStageAfter(game?.self_service_stage, 'running_player_fit_tests');

	useEffect(() => {
		if (game?.self_service_stage) {
			let currentLevel = 0;

			if (game.self_service_stage === 'versions_uploaded') {
				currentLevel = 1;
			} else if (game.self_service_stage === 'running_playtests') {
				if (isPlaytestCompleted) {
					currentLevel = 2;
				} else {
					currentLevel = 1;
				}
			} else if (game.self_service_stage === 'running_player_fit_tests') {
				if (isPlayerFitCompleted) {
					currentLevel = 3;
				} else {
					currentLevel = 2;
				}
			} else if (game?.self_service_stage === 'share_positive_poki_web_fit_score' || game?.self_service_stage === 'completed') {
				currentLevel = 4;
			} else {
				currentLevel = 3;
			}

			if (isGameJam && currentLevel > 2) {
				// Game Jam games can't go past the Player Fit level.
				currentLevel = 2;
			}

			setCurrent(currentLevel);
			setActiveLevel(currentLevel);
		}
	}, [game?.self_service_stage, isPlaytestCompleted, isPlayerFitCompleted, isGameJam]);

	const onClickCard = level => {
		setActiveLevel(level);
	};

	return (
		<ChecklistWrapper>
			{current === 0 && (
				<ChecklistCard
					id="create-game"
					title={_`addYourGame`}
					level="1"
					subComponent={(<Level1CreateGame />)}
					icon="upload"
					active={activeLevel === 0}
					current={current === 0}
					onClick={() => onClickCard(0)}
				/>
			)}

			<ChecklistCard
				id="playtest"
				title={_`getPlayerFeedback`}
				level="2"
				description={_`startGetPlayerFeedback`}
				checkType="coin"
				active={activeLevel === 1}
				current={current >= 1}
				game={game}
				onClick={() => onClickCard(1)}
				icon="game"
				noPadding
				steps={[
					{
						title: _`yourGamePreviewLink`,
						contentGenerator: PlaytestStep1,
					},
					{
						title: _`prepareYourGameForWeb`,
						contentGenerator: PlaytestStep2,
					},
					{
						title: _`startPlaytesting`,
						contentGenerator: PlaytestStep3,
					},
				]}
			/>
			<ChecklistCard
				id="playerfit"
				title="Player Fit Test"
				level="3"
				description={_`playerFitSubtitle`}
				subComponent={(<PlayerFitTest game={game} disabled={current < 2} />)}
				active={activeLevel === 2}
				current={current >= 2}
				game={game}
				onClick={() => onClickCard(2)}
				icon="graph"
				steps={[]}
			/>
			<ChecklistCard
				id="webfit"
				title={_`pokiWebFitTest`}
				level="4"
				description={_`feelingYourGameIsReady`}
				checkType="diamond"
				active={activeLevel === 3}
				current={current >= 3}
				game={game}
				onClick={() => onClickCard(3)}
				icon="diamond"
				steps={[
					{
						title: _`gameThumbnail`,
						contentGenerator: WebFitStep1,
					},
					{
						title: _`webFitStarts`,
						contentGenerator: WebFitStep2,
					},
					{
						title: _`webFitTestResults`,
						contentGenerator: WebFitStep3,
					},
				]}
			/>
			<ChecklistCard
				id="release-game"
				title={_`releaseYourGameOnPoki`}
				level="5"
				subComponent={(<Level4Description isTeamVerified={isTeamVerified} />)}
				active={activeLevel === 4}
				current={current >= 4}
				game={game}
				onClick={() => onClickCard(4)}
				icon="rocket"
				steps={[]}
			/>
		</ChecklistWrapper>
	);
};

export default GameOverviewChecklistModule;
