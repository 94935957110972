import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Subject } from 'rxjs';
import styled from 'styled-components';

import { registerModal } from 'app/src/modals';
import { preventPageLeave } from 'app/src/actions/client';

import Button from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import Modal from 'app/src/components/ui/Modal';

import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const ChangeEmailModal = props => {
	const dispatch = useDispatch();

	const { data } = props;
	const { confirm$ } = data;

	const [exit$] = useState(new Subject());

	const { handleSubmit, control, watch } = useForm({
		defaultValues: { emailConfirm: '' },
	});

	const { email } = watch();

	const onSubmit = () => {
		dispatch(preventPageLeave({ toggle: false }));
		confirm$.next();
		exit$.next();
	};

	return (
		<Modal
			exit$={exit$}
		>
			<h2>{_`confirmEmail`}</h2>
			<p dangerouslySetInnerHTML={{ __html: _`confirmEmailDescription${{ email: data.email }}` }} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					control={control}
					name="emailConfirm"
					render={({ field: { onChange, value } }) => (
						<TextInput
							name="emailConfirm"
							placeholder={_`exampleEmail`}
							value={value}
							valueSetter={onChange}
						/>
					)}
				/>
				<Buttons>
					<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
					<Button submit disabled={data.email !== email}>{_`sendNewSignInLink`}</Button>
				</Buttons>
			</form>
		</Modal>
	);
};

registerModal('change-email', ChangeEmailModal);
