import { tap, ignoreElements } from 'rxjs';

import { createAuthorizedApiEpic } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import downloadFromBase64 from 'app/src/utils/downloadFromBase64';

export const getPromptsList = createAuthorizedApiEpic(
	'guardian/list_prompts',
	callApi => callApi({
		url: getApiUrl('guardian', 'prompts/list'),
		method: 'GET',
	}),
);

export const createNewPrompt = createAuthorizedApiEpic(
	'guardian/create_new_prompt',
	(callApi, { formData }) => callApi({
		url: getApiUrl('guardian', 'prompts/create'),
		method: 'POST',
		body: formData,
	}),
);

export const downloadPromptsCSV = createAuthorizedApiEpic(
	'guardian/download_prompts_csv',
	(callApi, { id }) => callApi({
		url: getApiUrl('guardian', `prompts/getcsv?id=${id}`),
		method: 'POST',
	}),
	({ success$ }) => (
		success$.pipe(
			tap(({ payload: { result: { response } } }) => {
				const { data, filename } = response;
				downloadFromBase64(data, filename, 'text/csv');
			}),
			ignoreElements(),
		)
	),
);

export const testPrompt = createAuthorizedApiEpic(
	'guardian/test_prompt',
	(callApi, { prompt, message, context }) => callApi({
		url: getApiUrl('guardian', 'prompts/test'),
		method: 'POST',
		body: JSON.stringify({
			prompt,
			message,
			context,
		}),
	}),
);

export const downloadSampleMessages = createAuthorizedApiEpic(
	'guardian/download_sample_messages',
	(callApi, { type, limit, language }) => callApi({
		url: getApiUrl('guardian', `messages/latest?type=${type}&limit=${limit}&language=${language}`),
		method: 'POST',
	}),
	({ success$ }) => (
		success$.pipe(
			tap(({ payload: { result: { response } } }) => {
				const { data, filename } = response;
				downloadFromBase64(data, filename, 'text/csv');
			}),
			ignoreElements(),
		)
	),
);
