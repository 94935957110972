import React, { useState, useMemo, useEffect } from 'react';
import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import { Controller, useForm } from 'react-hook-form';
import { tap, map, ignoreElements } from 'rxjs';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useSelectIsAdmin, useSelectPermissions } from 'app/src/selectors/user';
import { patchTeam, getTeamByCode } from 'app/src/epics/team';
import { startScreenShake } from 'app/src/actions/effects';
import { openModal, preventPageLeave } from 'app/src/actions/client';
import checkPermissions from 'app/src/utils/checkPermissions';
import dataFormatter from 'app/src/utils/dataFormatter';
import countries from 'app/src/utils/countries';

import Button, { ButtonTextContainer, ButtonText } from 'app/src/components/ui/Button';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import GridContainer from 'app/src/components/ui/GridContainer';
import SelectInput from 'app/src/components/input/SelectInput';
import TextInput from 'app/src/components/input/TextInput';
import Card from 'app/src/components/ui/Card';

import { teamCustomerSegments } from 'shared/vars';
import _ from 'shared/copy';

const validationSchema = yup.object().shape({
	teamName: yup.string().required(_`fieldRequired`),
	primaryContactName: yup.string().required(_`fieldRequired`),
	primaryAddress: yup.string().when('$isAdmin', {
		is: isAdmin => !isAdmin,
		then: s => s.required(_`fieldRequired`),
	}),
	primaryCountry: yup.string().when('$isAdmin', {
		is: isAdmin => !isAdmin,
		then: s => s.oneOf(countries.map(c => c.iso_2_code), _`fieldRequired`),
	}),
});

const TeamSettingsGeneralSettingsSubPage = props => {
	const { team } = props;

	const dispatch = useDispatch();

	const countryValues = useMemo(() => countries.map(c => ({ value: c.iso_2_code, desc: c.name })), []);
	const [placeholderName] = useState(Math.random() < 0.5 ? _`placeholderNameA` : _`placeholderNameB`);
	const [isNavigation, setIsNavigation] = useState(false);

	const patchTeamStatus = useSelectApiStatus(patchTeam.id);
	const getTeamByCodeStatus = useSelectApiStatus(getTeamByCode.id);

	const permissions = useSelectPermissions();
	const isAdmin = useSelectIsAdmin();

	const canEdit = checkPermissions(permissions, [['can_edit_all_teams', 'can_edit_owned_teams']]);
	const fieldsDisabled = combinedApiStatus(patchTeamStatus, getTeamByCodeStatus).pending;

	const { handleSubmit, control, reset, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			teamName: team.name || '',
			teamCode: team.code || '',
			primaryContactName: team.primary_contact || '',
			primaryAddress: team.primary_address || '',
			primaryCountry: team.primary_country || '',
			privacyPolicyURL: team.privacy_policy_url || '',
			ccsp: team.custom_content_security_policy || '',
			customerSegment: team.customer_segment || '',
		},
		resolver: yupResolver(validationSchema),
		context: { isAdmin },
	});

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	useEffect(() => {
		if (!isNavigation) {
			dispatch(preventPageLeave({ toggle: isDirty }));
		}

		return () => {
			dispatch(preventPageLeave({ toggle: false }));
		};
	}, [isDirty, isNavigation]);

	const onSubmit = data => {
		const {
			teamName: name,
			teamCode: code,
			primaryContactName: primary_contact,
			primaryAddress: primary_address,
			primaryCountry: primary_country,
			privacyPolicyURL: privacy_policy_url,
			ccsp: custom_content_security_policy,
			customerSegment: customer_segment,
		} = data;

		setIsNavigation(true);

		dispatch(
			patchTeam.fetch(
				{
					teamId: team.id,
					data: { name, code, primary_contact, primary_address, primary_country, privacy_policy_url, custom_content_security_policy, customer_segment },
				},
				({ success$ }) => success$.pipe(
					map(({ payload: { result: { response } } }) => dataFormatter.deserialize(response)),
					tap(() => reset(data, { keepDirty: false })),
					ignoreElements(),
				),
			),
		);
	};

	const handleVerify = () => {
		const confirmationTitle = team.verified ? _`unverifyTeamTitle` : _`verifyTeamTitle`;
		const confirmationDescription = team.verified ? _`unverifiedTeamDescription` : _`verifiedTeamDescription`;

		dispatch(openModal({
			key: 'base-confirmation-modal',
			data: {
				title: confirmationTitle,
				description: confirmationDescription,
				onConfirm: () => {
					dispatch(patchTeam.fetch({ teamId: team.id, data: { verified: !team.verified } }));
				},
			},
		}));
	};

	return (
		<GridContainer cols={1}>
			<Helmet key={`TeamSettingsGeneralSettingsSubPage-${team.code}`}>
				<title>General Settings - {team.name} - Poki for Developers</title>
			</Helmet>
			<Card
				title={_`generalSettings`}
				buttons={isAdmin ? [
					team.verified ? (
						{
							id: 'unverify-team',
							type: 'button',
							action: handleVerify,
							secondary: true,
							negative: true,
							children: _`unverifyTeam`,
						}
					) : {
						id: 'verify-team',
						type: 'button',
						action: handleVerify,
						secondary: true,
						positive: true,
						children: _`verifyTeam`,
					},
				] : []}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<GridContainer cols={2}>
						<div>
							<Controller
								control={control}
								name="teamName"
								render={({ field: { onChange, value } }) => (
									<TextInput
										label={_`teamName`}
										required
										autoFocus
										value={value}
										valueSetter={onChange}
										placeholder={placeholderName}
										disabled={fieldsDisabled || !canEdit || !isAdmin}
										errors={errors?.teamName?.message ? [errors?.teamName?.message] : []}
									/>
								)}
							/>
							<Controller
								control={control}
								name="teamCode"
								render={({ field: { onChange, value } }) => (
									<TextInput
										label={_`teamCode`}
										placeholder={placeholderName}
										required
										value={value}
										valueSetter={onChange}
										disabled={fieldsDisabled || !canEdit || !isAdmin}
										errors={errors?.teamCode?.message ? [errors?.teamCode?.message] : []}
									/>
								)}
							/>
							<Controller
								control={control}
								name="primaryContactName"
								render={({ field: { onChange, value } }) => (
									<TextInput
										label={_`primaryContactName`}
										placeholder={placeholderName}
										required
										value={value}
										valueSetter={onChange}
										disabled={fieldsDisabled || !canEdit}
										errors={errors?.primaryContactName?.message ? [errors?.primaryContactName?.message] : []}
									/>
								)}
							/>
							{isAdmin && (
								<Controller
									control={control}
									name="privacyPolicyURL"
									render={({ field: { onChange, value } }) => (
										<TextInput
											label={_`privacyPolicyURL`}
											placeholder="https://example.org/privacy-statement.html"
											value={value}
											valueSetter={onChange}
											disabled={fieldsDisabled}
											errors={errors?.privacyPolicyURL?.message ? [errors?.privacyPolicyURL?.message] : []}
										/>
									)}
								/>
							)}
						</div>
						<div>
							<Controller
								control={control}
								name="primaryAddress"
								render={({ field: { onChange, value } }) => (
									<TextAreaInput
										label={_`primaryAddress`}
										placeholder={placeholderName}
										value={value}
										valueSetter={onChange}
										height="149px"
										resize={false}
										disabled={fieldsDisabled || !canEdit}
										required={!isAdmin}
										errors={errors?.primaryAddress?.message ? [errors?.primaryAddress?.message] : []}
									/>
								)}
							/>
							<Controller
								control={control}
								name="primaryCountry"
								render={({ field: { onChange, value } }) => (
									<SelectInput
										label={_`primaryCountry`}
										placeholder={_`selectACountry`}
										required={!isAdmin}
										value={value}
										valueSetter={onChange}
										values={countryValues}
										disabled={fieldsDisabled || !canEdit}
										errors={errors?.primaryCountry?.message ? [errors?.primaryCountry?.message] : []}
									/>
								)}
							/>
							{isAdmin && (
								<>
									<Controller
										control={control}
										name="ccsp"
										render={({ field: { onChange, value } }) => (
											<TextInput
												label={_`ccsp`}
												placeholder="default-src *.gameanalytics.com; script-src-elem *.gameanalytics.com"
												value={value}
												valueSetter={onChange}
												disabled={fieldsDisabled}
											/>
										)}
									/>

									<Controller
										control={control}
										name="customerSegment"
										render={({ field: { onChange, value } }) => (
											<SelectInput
												label={_`customerSegment`}
												valueSetter={onChange}
												value={value}
												values={teamCustomerSegments}
												disabled={fieldsDisabled}
												required
											/>
										)}
									/>
								</>
							)}
						</div>
					</GridContainer>
					{canEdit && (
						<ButtonTextContainer>
							<Button submit disabled={fieldsDisabled || !isDirty} primary>{_`save`}</Button>
							<ButtonText warning={!patchTeamStatus.pending || isDirty}>
								{patchTeamStatus.pending ? _`saving` : isDirty ? _`unsavedChanges` : fieldsDisabled ? _`loading` : ''}
							</ButtonText>
						</ButtonTextContainer>
					)}
				</form>
			</Card>
		</GridContainer>
	);
};

export default TeamSettingsGeneralSettingsSubPage;
