import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Subject, ignoreElements, map, tap } from 'rxjs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelectApiStatus, combinedApiStatus } from '@poki/rx-api';
import * as yup from 'yup';

import { startScreenShake } from 'app/src/actions/effects';
import { patchUser, emailExists } from 'app/src/epics/user';
import { openModal } from 'app/src/actions/client';
import { signoutWithNewEmail } from 'app/src/actions/session';
import { useSelectUser } from 'app/src/selectors/user';

import Button, { ButtonTextContainer, ButtonText } from 'app/src/components/ui/Button';
import TextInput from 'app/src/components/input/TextInput';
import Container from 'app/src/components/ui/Container';
import Card from 'app/src/components/ui/Card';

import _ from 'shared/copy';

const validationSchema = yup.object().shape({
	name: yup.string().required(_`fieldRequired`),
	email: yup.string().email(_`validate_email`).required(_`fieldRequired`),
});

const UserSettingsPage = () => {
	const dispatch = useDispatch();

	const [confirm$] = useState(new Subject());
	const [placeholderName] = useState(Math.random() < 0.5 ? _`placeholderNameA` : _`placeholderNameB`);

	const user = useSelectUser();
	const patchUserStatus = useSelectApiStatus(patchUser.id);
	const emailExistsStatus = useSelectApiStatus(emailExists.id);

	const { handleSubmit, control, reset, watch, getValues, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			name: user.name || '',
			email: user.email || '',
		},
		resolver: yupResolver(validationSchema),
	});

	const { nameWatch, emailWatch } = watch();

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	useEffect(() => {
		const { name, email } = getValues();

		const listen = confirm$.subscribe(() => {
			dispatch(patchUser.fetch({ userId: user.id, data: { name, email } }, ({ success$ }) => (
				success$.pipe(
					map(() => signoutWithNewEmail({ email })),
				)
			)));
		});

		return () => {
			listen.unsubscribe();
		};
	}, [confirm$, nameWatch, emailWatch]);

	const onSubmit = data => {
		const { name, email } = data;

		if (email !== user.email) {
			// Email was changed, check if it exists
			dispatch(openModal({ key: 'change-email', data: { email, confirm$ } }));
		} else {
			// Immediately change info
			dispatch(patchUser.fetch({ userId: user.id, data: { name } }, ({ success$ }) => (
				success$.pipe(
					tap(reset({ name, email }, { keepDirty: false })),
					ignoreElements(),
				)
			)));
		}
	};

	const disableFields = combinedApiStatus(patchUserStatus, emailExistsStatus).pending;

	return (
		<Container>
			<Helmet key="UserSettingsPage">
				<title>User Settings - Poki for Developers</title>
			</Helmet>
			<Card
				title={_`userSettings`}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name="name"
						render={({ field: { onChange, value } }) => (
							<TextInput
								label={_`fullName`}
								placeholder={placeholderName}
								valueSetter={onChange}
								value={value}
								disabled={disableFields}
								required
							/>
						)}
					/>
					<Controller
						control={control}
						name="email"
						render={({ field: { onChange, value } }) => (
							<TextInput
								label={_`email`}
								placeholder={_`exampleEmail`}
								description={_`emailChangeRequiresSignIn`}
								value={value}
								valueSetter={onChange}
								required
								disabled={disableFields}
								errors={errors?.email?.message ? [errors?.email?.message] : []}
							/>
						)}
					/>
					<ButtonTextContainer>
						<Button submit disabled={disableFields || !isDirty} primary>{patchUserStatus.pending ? _`saving` : _`save`}</Button>
						<ButtonText warning={!patchUserStatus.pending && isDirty}>
							{patchUserStatus.pending ? _`saving` : isDirty ? _`unsavedChanges` : ''}
						</ButtonText>
					</ButtonTextContainer>
				</form>
			</Card>
		</Container>
	);
};

export default UserSettingsPage;
