import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { useSelectPlayerFitTestsByGameId } from 'app/src/selectors/playerfit';
import { setGameChecklistStatus } from 'app/src/actions/session';
import { getPlayerFitTestsByGameId } from 'app/src/epics/playerfit';
import { openModal } from 'app/src/actions/client';
import getPlaytimeRange from 'app/src/utils/getPlaytimeRange';

import Button from 'app/src/components/ui/Button';
import TableLoader from 'app/src/components/ui/TableLoader';

const LevelPlayerFitContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	
	width: 100%;
	max-width: 444px;
	padding: 32px;

	background: ${props => props.theme.grey7};
	border-radius: 4px;
`;

const Buttons = styled.div`
	display: flex;
	gap: 16px;
`;

const LevelPlayerFitRunning = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`;

const PLAYTIME_THRESHOLD = 120;

const PlayerFitTest = ({ disabled, game }) => {
	const dispatch = useDispatch();

	const { data: playerFitTests } = useSelectPlayerFitTestsByGameId(game.id);

	const latestPlaytime = playerFitTests?.[0]?.engagement;
	const isGameJam = game.team?.customer_segment === 'game-jam';

	const isPlayerFitTestRunning = useMemo(() => (
		playerFitTests?.some(({ stopped_at }) => stopped_at === null)
	), [playerFitTests]);

	const onSuccessCallback = () => {
		dispatch(getPlayerFitTestsByGameId.fetch(game.id));
	};

	const handleStartNewPlayerFitTest = () => {
		const { id, approved, content_metadata: { release_status } = {} } = game;

		dispatch(openModal({
			key: 'start-new-playerfit-test',
			data: { gameId: id, gameApproved: approved, releaseStatus: release_status, onSuccessCallback },
		}));
	};

	useEffect(() => {
		dispatch(getPlayerFitTestsByGameId.fetch({ gameId: game.id }));
	}, []);

	useEffect(() => {
		dispatch(getPlayerFitTestsByGameId.fetch({ gameId: game.id }));

		const i = setInterval(() => {
			dispatch(getPlayerFitTestsByGameId.fetch({ gameId: game.id }));
		}, 10000);

		return () => {
			clearInterval(i);
		};
	}, []);

	const onSuccess = () => {
		dispatch(setGameChecklistStatus({ gameId: game.id, level: 'playerfit', step: 1, status: 'completed' }));
	};

	return (
		<LevelPlayerFitContainer>
			{isPlayerFitTestRunning ? (
				<LevelPlayerFitRunning>
					<TableLoader />
					Player Fit Test currently in progress. This can take up to 3 hours.
				</LevelPlayerFitRunning>
			) : (
				<>
					<div>
						<strong>Let Players Decide: The Player Fit Test</strong>
					</div>
					<div>
						At this stage, we’re focusing on playtime to ensure your game provides an engaging experience. To pass the test, your game’s average playtime <strong>must exceed 2 minutes</strong> during the first 500 gameplays.
					</div>
					<div>
						To maintain fairness and provide the best experience for all developers, you are limited to <strong>2 test requests per day</strong>. Use them to refine and improve your game before moving to the next stage.
					</div>
					<div>
						Before requesting a test, check if your game complies with our <a href="/2024.03.13_Terms_and_Conditions_Poki_for_Developers.pdf" download="2024-03-13 Terms and Conditions Poki for Developers.pdf" target="_blank"><strong>Terms & Conditions</strong></a>. Let’s work together to make great games for everyone!
					</div>
					{!isPlayerFitTestRunning && (
						<div>
							{playerFitTests && playerFitTests.length > 0 && (
								<div>
									<strong>Latest playtime range:</strong> {getPlaytimeRange(playerFitTests[0])}
								</div>
							)}
						</div>
					)}
					{latestPlaytime > PLAYTIME_THRESHOLD ? (
						isGameJam ? (
							<>
								<div>
									Results looking promising?
								</div>
								<div>
									Our testing flow is still in closed beta. Email us at <a href="mailto:developersupport@poki.com">developersupport@poki.com</a> and we can review your game to see if we can give you further access.
								</div>
							</>
						) : (
							<Buttons>
								<Button onClick={onSuccess} disabled={disabled}>Go to the next level</Button>
							</Buttons>
						)
					) : (
						<Buttons>
							<Button onClick={handleStartNewPlayerFitTest} disabled={disabled}>Start new Player Fit Test</Button>
						</Buttons>
					)}
				</>
			)}
		</LevelPlayerFitContainer>
	);
};

export default PlayerFitTest;
