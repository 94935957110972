import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { map, merge } from 'rxjs';
import moment from 'moment';
import styled from 'styled-components';

import { createNewPrompt, getPromptsList, downloadPromptsCSV, testPrompt, downloadSampleMessages } from 'app/src/epics/guardian';
import { useSelectPromptsList, useSelectTestResult } from 'app/src/selectors/guardian';

import { guardianLanguages } from 'app/src/utils/guardian';
import { dayMonthYearTimeFormat } from 'shared/vars';

import DownloadIcon from 'shared/designTokens/icons/ui/small/DownloadIcon';

import TextAreaInput from 'app/src/components/input/TextAreaInput';
import FileInput from 'app/src/components/input/FileInput';
import TextInput from 'app/src/components/input/TextInput';
import Button from 'app/src/components/ui/Button';
import Card from 'app/src/components/ui/Card';
import Table from 'app/src/components/ui/Table';
import Tooltip from 'shared/components/Tooltip';
import GridContainer from 'app/src/components/ui/GridContainer';
import IconButton from 'app/src/components/ui/IconButton';
import CopyToClipboard from 'app/src/components/CopyToClipboard';
import SelectInput from 'app/src/components/input/SelectInput';

const StyledTooltip = styled(Tooltip)`
	text-decoration: underline dotted;
`;

const AdminGuardianPage = () => {
	const dispatch = useDispatch();

	const [prompt, setPrompt] = useState(`
Analyse the following chat message to determine if it contains harmful content that should be blocked.

Mark the message as 'harmful' **only if**:
- It directly targets individuals or groups with hate speech, violent threats, or explicit harassment
- It contains clearly discriminatory remarks, scams, impersonation, or attempts to provoke harm
- It includes explicit sexual content or graphic violence inappropriate for general audiences

Profanity and edgy humor are **allowed**, as long as they are not excessively offensive or specifically aimed at harming someone. For example, "fuck this" or edgy meme jokes are fine, but "fuck you" or "you worthless idiot" should still be blocked.

This is for a meme generation game, so **meme-like content and humor are encouraged** unless they cross a line into clearly harmful territory. Use context and leniency when deciding.

Additionally, specify the ISO 639 language code for the detected language of the message, not the context (or XX if really not sure).

{{if_context}}
Here are the latest message including the history of the chat, also analyse it in this context:
\`\`\`
{{context}}
\`\`\`
{{end_if_context}}

This is the latest message:
\`\`\`
{{text}}
\`\`\`
		`.trim());
	const [message, setMessage] = useState('how are you?');
	const [context, setContext] = useState('hi\nhello');
	const [type, setType] = useState('CHAT');
	const [sampleType, setSampleType] = useState('CHAT');
	const [sampleLimit, setSampleLimit] = useState(100);
	const [language, setLangugage] = useState('');
	const [csvFile, setCsvFile] = useState();
	const [page, setPage] = useState(1);

	const createNewPromptStatus = useSelectApiStatus(createNewPrompt.id);
	const downloadPromptsCSVStatus = useSelectApiStatus(downloadPromptsCSV.id);
	const getPromptsListStatus = useSelectApiStatus(getPromptsList.id);
	const testPromptStatus = useSelectApiStatus(testPrompt.id);

	const prompts = useSelectPromptsList();
	const testResult = useSelectTestResult();

	const tableLoadingDone = (prompts && prompts.length > 0) || getPromptsListStatus.done;

	useEffect(() => {
		const int = setInterval(() => {
			dispatch(getPromptsList.fetch());
		}, 1000 * 5);

		return () => clearInterval(int);
	}, []);

	const handleCreatePrompt = () => {
		const formData = new FormData();
		formData.set('prompt', prompt.trim());
		formData.set('type', type);
		formData.set('csv', csvFile);

		dispatch(createNewPrompt.fetch({ formData }, ({ success$ }) => merge(
			success$.pipe(
				map(() => getPromptsList.fetch()),
			),
		)));
	};

	const handleDownload = id => {
		dispatch(downloadPromptsCSV.fetch({ id }));
	};

	const handleTestPrompt = () => {
		dispatch(testPrompt.fetch({ prompt: prompt.trim(), message, context: context.trim().split('\n') }));
	};

	const handleDownloadMessages = () => {
		dispatch(downloadSampleMessages.fetch({ type: sampleType, limit: parseInt(sampleLimit, 10), language }));
	};

	return (
		<GridContainer cols={1}>
			<Card
				title="Download latest messages"
			>
				<SelectInput
					label="Type"
					disabled={downloadSampleMessages.pending}
					value={sampleType}
					valueSetter={setSampleType}
					values={[
						{ value: 'CHAT', key: 'CHAT', desc: 'CHAT' },
						{ value: 'USERNAME', key: 'USERNAME', desc: 'USERNAME' },
						{ value: 'MEME', key: 'MEME', desc: 'MEME' },
					]}
				/>
				<SelectInput
					label="Language"
					disabled={downloadSampleMessages.pending}
					value={language}
					valueSetter={setLangugage}
					values={guardianLanguages.map(({ value, desc }) => ({ value, key: value, desc }))}
				/>
				<TextInput
					label="Amount"
					disabled={downloadSampleMessages.pending}
					value={sampleLimit}
					valueSetter={setSampleLimit}
				/>
				<Button onClick={handleDownloadMessages}>Download sample</Button>
			</Card>
			<Card
				title="Prompt"
			>
				<TextAreaInput
					label="Prompt"
					name="prompt"
					disabled={createNewPromptStatus.pending || testPromptStatus.pending}
					value={prompt}
					valueSetter={setPrompt}
					height="800px"
				/>
			</Card>
			<Card
				title="Test single message"
			>
				<TextAreaInput
					label="Context"
					name="context"
					disabled={testPromptStatus.pending}
					value={context}
					valueSetter={setContext}
				/>
				<TextAreaInput
					label="Message"
					name="message"
					disabled={testPromptStatus.pending}
					value={message}
					valueSetter={setMessage}
					height="50px"
				/>
				<Button onClick={handleTestPrompt}>Test Prompt</Button>
				{testResult && !testPromptStatus.pending && (
					<Card
						title="Test Result"
					>
						<pre>{JSON.stringify(testResult, null, 2)}</pre>
					</Card>
				)}
			</Card>
			<Card
				title="Test multiple messages"
			>
				<SelectInput
					label="Type"
					disabled={createNewPromptStatus.pending}
					value={type}
					valueSetter={setType}
					values={[
						{ value: 'CHAT', key: 'CHAT', desc: 'CHAT' },
						{ value: 'USERNAME', key: 'USERNAME', desc: 'USERNAME' },
						{ value: 'MEME', key: 'MEME', desc: 'MEME' },
					]}
				/>
				<FileInput
					label="CSV"
					accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					webkitdirectory=""
					directory=""
					required
					value={csvFile}
					valueSetter={setCsvFile}
				/>
				<Button onClick={handleCreatePrompt}>Test Prompt</Button>
				<Card
					title="Results"
					noPadding
				>
					<Table
						isLoading={!tableLoadingDone}
						items={prompts || []}
						setPagination={setPage}
						page={page}
						perPage={10}
						totalItems={(prompts || []).length}
						autoPaging
						columns={[
							{
								title: 'ID',
								content: ({ item }) => item.id,
							},
							{
								title: 'created',
								content: ({ item }) => moment(item.created_at).format(dayMonthYearTimeFormat),
							},
							{
								title: 'prompt',
								content: ({ item }) => (
									item.prompt.length > 10 ? (
										<CopyToClipboard text={item.prompt} />
									) : null
								),
							},
							{
								title: 'Type',
								content: ({ item }) => item.type,
							},
							{
								title: 'Samples',
								content: ({ item }) => item.sample_size,
							},
							{
								title: 'old reject rate',
								content: ({ item }) => {
									const total = item.old_reject_count + item.old_accept_count;
									const percentage = (total === 0) ? 0 : Math.round((item.old_reject_count / total) * 100);

									return (
										<StyledTooltip
											arrow
											placement="top-end"
											maxWidth="250px"
											content={(
												<>
													{item.old_reject_count} / {total}
												</>
											)}
										>
											{percentage}%
										</StyledTooltip>
									);
								},
							},
							{
								title: 'new reject rate',
								content: ({ item }) => {
									const total = item.new_reject_count + item.new_accept_count;
									const percentage = (total === 0) ? 0 : Math.round((item.new_reject_count / total) * 100);

									return (
										<StyledTooltip
											arrow
											placement="top-end"
											maxWidth="250px"
											content={(
												<>
													{item.new_reject_count} / {total}
												</>
											)}
										>
											{percentage}%
										</StyledTooltip>
									);
								},
							},
							{
								title: 'finished',
								content: ({ item }) => (item.finished ? 'Yes' : `No (${Math.round(((item.old_accept_count + item.old_reject_count) / item.sample_size) * 100)}%)`),
							},
							{
								title: 'download results',
								content: ({ item }) => (
									<IconButton
										icon={DownloadIcon}
										onClick={() => handleDownload(item.id)}
										disabled={downloadPromptsCSVStatus.pending}
										title={downloadPromptsCSVStatus.pending ? 'downloading' : 'download results'}
									/>
								),
							},
						]}
					/>
				</Card>
			</Card>
		</GridContainer>
	);
};

export default AdminGuardianPage;
