import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';

import PlusIcon from 'shared/designTokens/icons/ui/small/PlusIcon';

import { useSelectGamesByTeamId, selectLastUploadedVersionByGame } from 'app/src/selectors/game';
import { openModal } from 'app/src/actions/client';
import { listGamesByTeamId } from 'app/src/epics/game';
import { useSelectUser } from 'app/src/selectors/user';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import getGameThumbnailUrl from 'app/src/utils/getGameThumbnailUrl';
import history from 'app/history';

import ListMenu from 'app/src/components/ui/ListMenu';
import { LabelTypes } from 'app/src/components/ui/Label';

import _ from 'shared/copy';

const GamesMenu = props => {
	const { scrollContainerRef } = props;

	const user = useSelectUser();
	const isAdmin = user.role === 'admin';
	const activeTeam = useSelectActiveTeam();
	const { data: games = [] } = useSelectGamesByTeamId(activeTeam?.id);
	const listGamesByTeamIdStatus = useSelectApiStatus(listGamesByTeamId.id);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(listGamesByTeamId.fetch({ teamId: activeTeam.id }));
	}, [activeTeam.id]);

	// Skip loading state if we already have games, to prevent re-entering loading state when refreshing game list
	const apiStatus = { ...listGamesByTeamIdStatus };
	if (listGamesByTeamIdStatus.timesCompleted > 0 && games?.length > 0) {
		apiStatus.pending = false;
	}

	const gameButtons = useMemo(() => (
		(games || []).map(game => {
			const version = selectLastUploadedVersionByGame(game.versions);

			return {
				id: game.id,
				icon: getGameThumbnailUrl(game.thumbnail_url, 24),
				name: game.title,
				needsPermissions: [['can_read_all_games', 'can_read_owned_games']],
				isActiveFunction: currentPath => currentPath.startsWith(`/${activeTeam.code}/games/${game.id}`),
				children: [
					{
						to: `/${activeTeam.code}/games/${game.id}`,
						name: _`overview`,
						needsPermissions: [
							['can_read_all_games', 'can_read_owned_games'],
							game?.annotations?.license_fee === 'yes' ? ['can_see_all_clickhouse_data'] : null,
						],
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/versions`,
						name: _`versions`,
						needsPermissions: [['can_read_all_versions', 'can_read_owned_versions']],
					},
					{
						to: `https://inspector.poki.dev/?game=poki-${version?.id}${isAdmin ? '&analytics=false' : ''}`,
						name: _`inspector`,
						isExit: true,
						needsPermissions: [['can_read_all_versions', 'can_read_owned_versions']],
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/player-feedback`,
						name: _`playerFeedback`,
						needsPermissions: [
							(game?.content_metadata?.release_status && game?.content_metadata?.release_status !== 'not-released') ? ['can_read_owned_player_feedback', 'can_read_all_player_feedback'] : ['can_read_all_player_feedback'],
							game?.annotations?.license_fee === 'yes' ? ['can_see_all_clickhouse_data'] : null,
						],
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/errors`,
						name: _`errors`,
						needsPermissions: [
							['can_read_all_games', 'can_read_owned_games'],
							game?.annotations?.license_fee === 'yes' ? ['can_see_all_clickhouse_data'] : null,
						],
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/playtests`,
						name: _`playtest`,
						needsPermissions: [game?.annotations?.license_fee === 'yes' ? ['can_read_all_playtest_recordings'] : ['can_read_all_playtest_recordings', 'can_read_owned_playtest_recordings']],
						label: {
							type: LabelTypes.LABS,
							notification: game?.playtest_recordings_unwatched || 0,
						},
						isActiveFunction: currentPath => currentPath.startsWith(`/${activeTeam.code}/games/${game.id}/playtests`),
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/playerfit`,
						name: _`playerFitTests`,
						needsPermissions: [game?.approved ? ['can_read_all_player_fit_tests'] : ['can_read_all_player_fit_tests', 'can_read_owned_player_fit_tests']],
						label: {
							type: LabelTypes.LABS,
						},
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/game-events`,
						name: _`gameEvents`,
						needsPermissions: [['can_read_all_game_events', 'can_read_owned_game_events'], game?.annotations?.license_fee === 'yes' ? ['can_see_all_clickhouse_data'] : null],
						label: {
							type: LabelTypes.LABS,
						},
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/settings`,
						name: _`settings`,
					},
					{
						to: `/${activeTeam.code}/games/${game.id}/admin-settings`,
						name: _`adminSettings`,
						needsPermissions: [['can_edit_all_games', 'can_edit_owned_games']],
					},
				],
			};
		}).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
	), [games]);

	return (
		<>
			<ListMenu
				alwaysShow
				apiStatus={apiStatus}
				title={_`games`}
				buttons={gameButtons}
				scrollContainerRef={scrollContainerRef}
			/>
			<ListMenu
				buttons={[
					{
						icon: PlusIcon,
						name: _`addGame`,
						isActiveFunction: currentPath => currentPath.startsWith(`/${activeTeam.code}/games/create`),
						onClick: () => {
							if (activeTeam.verified && user.role === 'admin') {
								dispatch(openModal({ key: 'create-game' }));
							} else {
								history.push(`/${activeTeam.code}/games/create`);
							}
						},
						needsPermissions: [['can_create_all_games', 'can_create_owned_games']],
					},
				]}
			/>
		</>
	);
};

export default GamesMenu;
