import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelectApiStatus } from '@poki/rx-api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ignoreElements, merge, tap } from 'rxjs';
import history from 'app/history';
import * as yup from 'yup';

import { clearExternalFileURL, setExternalFileUpload } from 'app/src/actions/session';
import { useSelectExternalFileURL } from 'app/src/selectors/session';
import { createGameAndVersion } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { openToast } from 'app/src/actions/client';
import getRandomGameTitle from 'app/src/utils/getRandomGameTitle';
import getExternalFileByURL from 'app/src/utils/getExternalFileByURL';
import prettyFileSize from 'app/src/utils/prettyFileSize';

import ExitLinkIcon from 'shared/designTokens/icons/ui/tiny/ExitLinkIcon';

import { ToastTypes } from 'app/src/components/ui/Toast';
import FileInput from 'app/src/components/input/FileInput';
import ProgressBar from 'app/src/components/ui/ProgressBar';
import TextInput from 'app/src/components/input/TextInput';
import Button from 'app/src/components/ui/Button';
import Card from 'app/src/components/ui/Card';

import { smallMedia, isMobile } from 'shared/vars';
import _ from 'shared/copy';

const FormContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 40px;

	${smallMedia}{
		flex-wrap: nowrap;
	}
`;

const Form = styled.form`
	margin-top: 20px;
	width: 100%;
`;

const UploadText = styled.div`
	font-weight: bold;
	margin-bottom: 10px;
	margin-top: 25px;
`;

const UploadProgressBar = styled(ProgressBar)`
	width: 100%;
	transition: opacity 0.2s ease-out;

	${props => props.inactive && `
	opacity: 0.1;
	`}
`;

const StyledFileInput = styled(FileInput)`
	${props => (props.$hidden ? `
	display: none;
	` : '')}
`;

const BorderCard = styled(Card)`
	width: 100%;
	border: 2px solid ${props => props.theme.grey7};
	box-shadow: none;
	height: fit-content;

	h3{
		font-size: 16px;
	}

	${smallMedia}{
		width: 256px;
		min-width: 256px;
	}
`;

const BorderCardContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const EngineContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	align-content: center;
	padding: 2px 0;
	gap: 0px 8px;
	width: 100%;
	margin-top: 12px;
	background: white;
	border-radius: 8px;

	${smallMedia} {
		width: 208px;
	}
`;

const EngineContainerImageWrap = styled.div`
	max-width: 263px;
	margin: 0 auto;
	text-align: center;
`;

const EngineImage = styled.img`
	width: 100px;
`;

const StyledLinkIcon = styled(ExitLinkIcon)`
	width: 18px;
	height: 18px;
`;
const StyledLink = styled.a`
	display: flex;
	align-items: center;
	gap: 4px;
	font: 700 16px/24px "Proxima Nova";
`;

const StyledExternalResources = styled.div`
	font: 400 14px/18px "Proxima Nova";
	> a {
		font-weight: bold;
	}
	margin: 0 0 16px;
`;

const validationSchema = yup.object().shape({
	game_title: yup.string().required(_`fieldRequired`),
	version_file: yup.mixed().required(_`fieldRequired`),
});

const Level1CreateGame = () => {
	const dispatch = useDispatch();

	const createGameAndVersionStatus = useSelectApiStatus(createGameAndVersion.id);
	const activeTeam = useSelectActiveTeam();
	const externalFileURL = useSelectExternalFileURL();

	const [isUploading, setIsUploading] = useState(false);
	const [description] = useState(_`gameTitleSuggestions${{ titles: [getRandomGameTitle(), getRandomGameTitle(), getRandomGameTitle()] }}`);

	const { pending, progress, progressEvent } = createGameAndVersionStatus;

	const { handleSubmit, setError, setValue, control, formState: { errors } } = useForm({
		resolver: yupResolver(validationSchema),
	});

	const fetchExternalFile = useCallback(({ url }) => {
		getExternalFileByURL(url, file => {
			// Custom behavior for setting the file input value
			setValue('version_file', file, { shouldValidate: true });
			dispatch(setExternalFileUpload({ isOpen: true }));
			dispatch(clearExternalFileURL());
		}, err => {
			setError('version_file', { message: err.message });
			dispatch(setExternalFileUpload({ isOpen: true }));
			dispatch(clearExternalFileURL());
		});
	}, [setValue]);

	useEffect(() => {
		if (externalFileURL) {
			fetchExternalFile({ url: externalFileURL });
		}
	}, [externalFileURL, fetchExternalFile]);

	const onSubmit = data => {
		const { game_title, version_file } = data;

		const formData = new FormData();
		formData.set('game-title', game_title);
		formData.set('file', version_file);

		setIsUploading(true);

		dispatch(createGameAndVersion.fetch({ formData }, ({ success$, error$ }) => merge(
			success$.pipe(
				tap(({ payload: { result: { response } } }) => {
					const { game_id } = response;
					history.push(`/${activeTeam.code}/games/${game_id}`);
				}),
				ignoreElements(),
			),
			error$.pipe(
				tap(({ payload: { result: { response } } }) => {
					const { message, status } = response;

					dispatch(startScreenShake());

					if (status === 400 || status === 413) {
						setError('version_file', { message });
					} else {
						openToast({ body: `Something went wrong: ${message}`, toastType: ToastTypes.WARNING });
					}
				}),
				ignoreElements(),
			),
		)));
	};

	return (
		<FormContainer>
			{isMobile
				? _`mobileCreateGame`
				: (
					<div>
						{_`letsUpload`}
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Controller
								control={control}
								name="version_file"
								render={({ field: { onChange, value } }) => (
									<StyledFileInput
										label={_`uploadAZipOrFolder`}
										accept="application/zip, application/x-zip-compressed"
										webkitdirectory=""
										directory=""
										multiple
										required
										value={value}
										valueSetter={onChange}
										errors={errors?.version_file?.message ? [errors?.version_file?.message] : []}
									/>
								)}
							/>
							<StyledExternalResources dangerouslySetInnerHTML={{ __html: _`externalResources` }} />

							<Controller
								control={control}
								name="game_title"
								render={({ field: { onChange, value } }) => (
									<TextInput
										label={_`gameTitle`}
										placeholder={_`enterGameTitle`}
										value={value}
										valueSetter={onChange}
										required
										autoFocus
										description={description}
										errors={errors?.game_title?.message ? [errors?.game_title?.message] : []}
									/>
								)}
							/>
							<Button submit disabled={pending}>{pending ? _`creating` : _`addGame`}</Button>

							{(isUploading && progressEvent && progress < 1) && (
								<>
									<UploadText>
										{_`uploading`} {prettyFileSize(progressEvent.loaded)} / {prettyFileSize(progressEvent.total)}
									</UploadText>
									<UploadProgressBar progress={progress} inactive={progress >= 1} />
								</>
							)}
						</Form>
					</div>
				)}
			<BorderCard
				title={_`webGuideBoxTitle`}
				noPadding
				description={(
					<BorderCardContent>
						<EngineContainer>
							<EngineContainerImageWrap>
								{['unity', 'defold', 'godot'].map(
									engine => (
										<EngineImage
											key={engine}
											src={`/images/web-guide-${engine}.png`}
											alt={`${engine} engine`}
										/>
									),
								)}
							</EngineContainerImageWrap>
						</EngineContainer>
						{_`webGuideBoxDescription`}
						<StyledLink href="https://developers.poki.com/guide/web-game-engines" target="_blank" rel="noopener noreferrer">
							<StyledLinkIcon /> {_`learnMore`}
						</StyledLink>
					</BorderCardContent>
				)}
			/>

		</FormContainer>
	);
};
export default Level1CreateGame;
