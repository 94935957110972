/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useContext, useState, useRef, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import * as d3 from 'd3';
import moment from 'moment';

import { screenSpaceToSVGSpace } from 'app/src/utils/svgMath';
import { useSelectViewport } from 'app/src/selectors/client';
import { useSelectTheme } from 'app/src/selectors/session';
import formatNumber from 'app/src/utils/formatNumber';
import formatTime from 'app/src/utils/formatTime';

import ChartLoader from 'app/src/components/ui/ChartLoader';

import { motionSpeed02, motionOut } from 'shared/vars';
import _ from 'shared/copy';

/* Animations */

const danceKeyframes = keyframes`
	from {
		content: '♪┗(・o･)┓♪';
	}

	to {
		content: '♪┏(・o･)┛♪';
	}
`;

const danceAnimation = css`${danceKeyframes} 1s linear infinite`;

const fadeInKeyframes = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

const fadeInAnimation = css`${fadeInKeyframes} ${motionSpeed02} ${motionOut}`;

/* Styled Components */

export const ChartContainer = styled.div`
	position: relative;
	z-index: 1;

	@media (max-width: 550px) {
		margin: 0 -12px;
	}
`;

const StateTitle = styled.div`
	text-align: center;
	color: ${props => props.theme.grey3};
	font-size: 16px;
	font-weight: bold;
`;

const StateMessage = styled.div`
	font-size: 12px;
	color: ${props => props.theme.grey3};
	text-align: center;
	line-height: 1.5em;
`;

const State = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;

	${props => props.error && `
	${StateTitle} {
		color: ${props.theme.rose1};
	}
	`}

	${props => props.pending && css`
	${StateMessage} {
		::before {
			content: '';
			animation: ${danceAnimation};
		}
	}
	`}
`;

const SVG = styled.svg`
	display: inline-block;
	position: absolute;
	left: 0;
	top: 0;
	animation: ${fadeInAnimation};
	overflow: visible;
`;

const GridLine = styled.path`
	fill: none;
	stroke: ${props => props.theme.grey7};
	stroke-width: 1;
`;

const XGridLine = styled(GridLine)`
	stroke: none;
`;

const YGridLine = styled(GridLine)`

`;

const AxisLine = styled.path`
	fill: none;
	stroke: ${props => props.theme.grey7};
	stroke-width: 1px;
`;

const XAxisLine = styled(AxisLine)`
`;

const YAxisLine = styled(AxisLine)`
	stroke: none;
`;

const AxisTick = styled.path`
	stroke: none;
`;

const AxisTickText = styled.text`
	fill: ${props => props.theme.grey3};
	font-size: 12px;
	font-weight: bold;

	${props => props.small && `
	font-size: 10px;
	`}
`;

const AxisLabel = styled.text`
	fill: ${props => props.theme.denimBlue};
	font-size: 0.5em;
	font-weight: bold;
`;

const Line = styled.path`
	fill: none;
	stroke-width: 1.5px;
`;

const Area = styled.path`
	opacity: 0.2;
	stroke-width: 0.5px;
`;

const Bar = styled.rect`
`;

const HoverLine = styled.path`
	fill: none;
	stroke: ${props => props.theme.grey5};
	stroke-width: 1px;
	stroke-dasharray: 2 4;
	opacity: 0.5;
	pointer-events: none;
`;

const HoverHelper = styled.circle`
	fill: none;
	stroke-width: 1px;
	pointer-events: none;
	fill: ${props => props.theme.static.pureWhite};
`;

const GroupName = styled.div`
	flex-shrink: 0;
	display: flex;
	align-items: center;
	margin-right: 16px;
`;

const GroupNameWrap = styled.span`
	max-width: 160px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const GroupValue = styled.div`
	flex-shrink: 0;
	text-align: right;

	${props => props.$small && `
	font-weight: normal;
	`}
`;

const GroupGlyph = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 6px;
	background: ${props => props.color};
	margin-right: 8px;
	flex-shrink: 0;
`;

const LegendInner = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	flex-wrap: wrap;
`;

const LegendContainer = styled.div`
	display: flex;
	animation: ${fadeInAnimation};
	font-size: 12px;
	line-height: 16px;
	font-weight: bold;
	color: ${props => props.theme.grey3};
	margin-top: 16px;
	margin-bottom: 16px;
	min-height: 32px;

	${GroupName} {
		margin-right: 16px;
		max-width: 200px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const TooltipContainer = styled.div`
	pointer-events: none;
	position: absolute;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 16px;
	background: ${props => props.theme.pureWhite};
	border-radius: 8px;
	border: 1px solid ${props => props.theme.grey7};
	box-shadow: ${props => props.theme.boxShadowSmall};
	width: max-content;
	transform: translate(${props => (props.$flip ? 'calc(-100%)' : '0%')}, -50%);
`;

const XValue = styled.div`
	font-weight: bold;
`;

const Group = styled.div`
	display: flex;
	align-items: center;
	flex-shrink: 0;
`;

const LegendGroup = styled(Group)``;

const TooltipGroup = styled(Group)`
	font-size: 14px;
	line-height: 20px;
	margin-top: 8px;
	color: ${props => props.theme.grey3};
	font-weight: bold;
	display: flex;
	justify-content: space-between;

	${GroupGlyph} {
		margin-right: 8px;
	}

	${props => props.$total && `
	color: ${props.theme.denimBlue};
	`}

	${props => props.$extra && `
	color: ${props.theme.denimBlue};
	margin-top: 0;
	font-size: 12px;
	`}

	${props => props.$small && `
	font-weight: normal;
	font-size: 12px;
	`}
`;

const TooltipGrid = styled.div`
	display: grid;
	gap: 8px 12px;
	margin-top: 8px;

	${TooltipGroup} {
		display: contents;
	}
`;

const TooltipDivider = styled.div`
	grid-column: 1/-1;
	background: ${props => props.theme.grey7};
	height: 1px;
`;

/* Configuration */

const ChartContext = React.createContext({});

const defaultMargins = {
	left: 45,
	bottom: 20,
	right: 25,
	top: 10,
};

/* Methods */

const SCALE_TYPES = {
	LINEAR: 0,
	TIME: 1,
	BAND: 2,
};

const formatField = (type = 'integer', value, isTick = false) => {
	switch (type) {
		case 'eur':
		case 'usd':
			return `${type === 'usd' ? '$' : '€'}${formatNumber(value, { shorten: true, allowDecimals: !isTick })}`;

		case 'date':
			const date = moment(value);
			date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }); // reset time to ensure we can match yesterday data

			const yesterday = moment();
			yesterday.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			yesterday.subtract(1, 'day');

			if (isTick && date.isSame(yesterday)) {
				return _`yesterday`;
			}

			return d3.timeFormat(`${!isTick ? '%a %e %b %Y' : '%e %b'}`)(date);

		case 'time':
			// We assume input is in seconds
			return formatTime(value, !isTick);

		case 'smallNumber':
			return Number(value).toFixed(2);

		case 'integer':
			return formatNumber(value, { shorten: true });

		case 'percentile':
			return `${formatNumber(value * 100, { shorten: true })}%`;
	}
};

const getGroups = ({ getGroupedBy, data }) => Array.from(d3.group(data, getGroupedBy), ([key, values]) => ({ key, values }));

const getGroupKeys = props => {
	const { keyOrder } = props;

	const keys = getGroups(props).reduce((groups, current) => [current.key, ...groups], []);

	if (!keyOrder) return keys;

	return keys.sort((a, b) => (
		keyOrder.indexOf(a) < keyOrder.indexOf(b) ? -1 : 1
	));
};

const getBarX = (xScale, xValue) => xScale(xValue);

const getStackedData = props => {
	const { data, xAxis, yAxis, getGroupedBy } = props;

	const keys = getGroupKeys(props);

	// Prepare data for stacking
	const preStack = [];
	data.forEach(d => {
		preStack[d[xAxis.field]] = preStack[d[xAxis.field]] || {};
		preStack[d[xAxis.field]][getGroupedBy(d)] = d[yAxis.field];
		preStack[d[xAxis.field]][xAxis.field] = d[xAxis.field];
	});

	// Stack data
	return d3.stack()
		.keys(keys)(Object.values(preStack));
};

const groupColorGetter = (props, groupColors) => (
	d3.scaleOrdinal()
		.domain(getGroupKeys(props))
		.range(groupColors)
);

const getXRange = ({ data, xAxis, xRange }) => xRange || d3.extent(data, d => d[xAxis.field]);

const getDatesBetweenRange = (startDate, endDate) => {
	const dates = [];
	const currentDate = startDate.clone();
	currentDate.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }); // 'Remove' time to ensure we can match input data;

	while (currentDate <= endDate) {
		dates.push(currentDate.format('YYYY-MM-DD'));
		currentDate.add(1, 'days');
	}

	return dates;
};

const getMargins = props => {
	const { margins } = props;

	return {
		left: margins.left || defaultMargins.left,
		top: margins.top || defaultMargins.top,
		right: margins.right || defaultMargins.right,
		bottom: margins.bottom || defaultMargins.bottom,
	};
};

const getXScale = props => {
	const { data, type, width, xAxis, xRange } = props;
	const margins = getMargins(props);

	if (type === 'bar') {
		const scale = d3.scaleBand()
			.domain(xRange && xAxis.type === 'date' ? getDatesBetweenRange(xRange[0], xRange[1]) : data.map(d => d[xAxis.field]))
			.paddingInner(0.15)
			.paddingOuter(0.3)
			.range([0, width - margins.left - margins.right]);

		scale.type = SCALE_TYPES.BAND;
		return scale;
	}

	if (xAxis.type === 'date') {
		const scale = d3.scaleTime()
			.domain(getXRange(props))
			.range([0, width - margins.left - margins.right]);
		scale.type = SCALE_TYPES.TIME;
		return scale;
	}

	const scale = d3.scaleLinear()
		.domain(getXRange(props))
		.range([0, width - margins.left - margins.right]);
	scale.type = SCALE_TYPES.LINEAR;

	return scale;
};

const getXTicks = (props, xScale) => xScale.domain();

const getYTicks = (props, yScale) => {
	// For percentile charts that max at 1 we have hardcoded ticks
	// because we know what we like
	if (props.yAxis.type === 'percentile') {
		const yDomain = yScale.domain();
		if (yDomain[1] === 1) {
			// Note that we want an uneven amount of ticks
			// to ensure we see the last and first ticks
			return [0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1];
		}
	}

	return yScale.ticks(props.width < 400 ? 5 : 7);
};

const buildYScale = (props, range) => {
	const { height } = props;
	const margins = getMargins(props);

	const scale = d3.scaleLinear()
		.domain(range)
		.range([height - margins.bottom - margins.top, 0]);

	scale.type = SCALE_TYPES.LINEAR;

	return scale;
};

const getYRange = props => {
	const { data, xAxis, yAxis, stacked } = props;

	if (data.length === 0) return [0, 0];

	let entries = data;

	if (stacked) {
		// Get unique X values across all groups
		const uniqueX = d3.rollup(
			data,
			d => d3.sum(d, _d => _d[yAxis.field]),
			d => d[xAxis.field],
		);
		entries = Array.from(uniqueX, ([key, value]) => ({ key, value }));
	}

	const extent = d3.extent(entries, d => (stacked ? d.value : d[yAxis.field]));

	const min = 0;
	let max = extent[1];

	const minimumMax = yAxis.type === 'percentile' ? 0 : 0.6;

	if (!Number.isFinite(max)) {
		max = minimumMax;
	}

	if (yAxis.type === 'percentile' && (max >= 0.7 && max < 1)) {
		// For percentile charts we want to really try to lock to 100%
		// if we are close to it.
		max = 1;
	} else { // Otherwise we have some dynamic logic
		max = Math.max(minimumMax, extent[1] * 1.1); // Ensure there's always a buffer so bars don't reach the top

		// Increase the max value of yScale until we have an uneven amount of ticks
		// this ensures the top grid line of the chart always has a tick
		// we have to do this because the yTicks method cannot guarantee an uneven amount of ticks
		let ticksAmount;
		let iterations = 0;
		let tmpMax = max;

		do {
			iterations++;
			tmpMax *= 1.1;
			ticksAmount = getYTicks(props, buildYScale(props, [min, tmpMax])).length;

			if (iterations > 10) {
				// At least we tried... settle on original max
				tmpMax = max;
				break;
			}
		} while (ticksAmount % 2 === 0);

		max = tmpMax;
	}

	return [min, max];
};

const getYScale = props => buildYScale(props, getYRange(props));

const GridLines = React.memo(() => {
	const {
		width,
		height,
		xScale,
		yScale,
		xTicks,
		yTicks,
		margins,
	} = useContext(ChartContext);

	const x = margins.left;
	const y = margins.top;

	return (
		<g transform={`translate(${x}, ${y})`}>
			{xTicks
				.filter(tick => tick !== undefined && tick !== null && !Number.isNaN(tick))
				.map((tick, idx) => (
					<XGridLine
						key={`${tick}-${idx}`}
						d={`M${xScale(tick)},0 ${xScale(tick)},${height - margins.top - margins.bottom}`}
					/>
				))}
			{yTicks
				.filter(tick => tick !== undefined && tick !== null && !Number.isNaN(tick))
				.filter((t, idx) => (yTicks.length < 8 ? true : idx % 2 === 0))
				.map((tick, idx) => (
					<YGridLine
						key={`${tick}-${idx}`}
						d={`M0,${yScale(tick)} ${width - margins.left - margins.right},${yScale(tick)}`}
					/>
				))}
		</g>
	);
});

const XAxis = React.memo(() => {
	const {
		width,
		height,
		showXAxisLabel,
		showXAxis,
		xAxis,
		xScale,
		xTicks,
		margins,
	} = useContext(ChartContext);

	const x = margins.left;
	const y = height - margins.bottom;
	const tickSize = 5;
	let targetXTicks = 7;

	if (width < 400) {
		targetXTicks = 5;
	}

	if (width < 300) {
		targetXTicks = 3;
	}

	return (
		<>
			{showXAxisLabel && (
				<AxisLabel
					textAnchor="middle"
					alignmentBaseline="baseline"
					x={margins.left + ((width - margins.left - margins.right) / 2)}
					y={height}
				>
					{xAxis.displayName || xAxis.field}
				</AxisLabel>
			)}
			{showXAxis && (
				<g transform={`translate(${x},${y})`}>
					<XAxisLine
						d={`M0,0 L${width - margins.right - margins.left},0`}
					/>
					{xTicks
						.filter((tick, idx) => {
							const mod = Math.ceil(xTicks.length / targetXTicks);
							const offset = (xTicks.length - 1) % mod; // Ensure last value is always visible
							return (idx % mod) - offset === 0;
						})
						.map(tick => (
							<g id={tick} key={tick} transform={`translate(${xScale(tick) + (xScale.bandwidth() / 2)},${tickSize})`}>
								<AxisTick
									d={`M0,0 0,${-tickSize}`}
								/>
								<AxisTickText
									small={width < 450}
									textAnchor="middle"
									alignmentBaseline="top"
									y={5 + tickSize}
								>
									{xAxis.customFormat ? xAxis.customFormat(tick) : formatField(xAxis.type, tick, true)}
								</AxisTickText>
							</g>
						))}
				</g>
			)}
		</>
	);
});

const YAxis = React.memo(() => {
	const {
		height,
		showYAxisLabel,
		showYAxis,
		yAxis,
		yScale,
		yTicks,
		yRange,
		margins,
	} = useContext(ChartContext);

	const x = margins.left;
	const y = margins.top;
	const tickSize = 4;

	return (
		<>
			{showYAxisLabel && (
				<AxisLabel
					textAnchor="middle"
					alignmentBaseline="top"
					x={-((height - margins.bottom - margins.top) / 2) - margins.top} /* Note that x = y and y = x because text is rotated */
					y="5"
					transform="rotate(-90)"
				>
					{yAxis.displayName || yAxis.field}
				</AxisLabel>
			)}
			{showYAxis && (
				<g transform={`translate(${x}, ${y})`}>
					{!Number.isNaN(yRange[0]) && !Number.isNaN(yRange[1]) && (
						<YAxisLine
							d={`M0,${yScale(yRange[0])} L0,${yScale(yRange[1])}`}
						/>
					)}
					{yTicks
						.filter((tick, idx) => idx % 2 === 0) // Only show half of the ticks
						.map(tick => (
							<g key={tick} transform={`translate(${-tickSize},${yScale(tick)})`}>
								<AxisTick
									d={`M0,0 ${tickSize},0`}
								/>
								<AxisTickText
									textAnchor="end"
									alignmentBaseline="mathematical" /* mathematical = actually working 'center' */
									x={-tickSize}
								>
									{yAxis.customFormat ? yAxis.customFormat(tick) : formatField(yAxis.type, tick, true)}
								</AxisTickText>
							</g>
						))}
				</g>
			)}
		</>
	);
});

const LineChart = React.memo(() => {
	const {
		xAxis,
		yAxis,
		xScale,
		yScale,
		groupedData,
		getGroupColor,
		curve,
	} = useContext(ChartContext);

	let line = d3.line()
		.defined(d => !Number.isNaN(d[yAxis.field]))
		.x(d => xScale(d[xAxis.field]))
		.y(d => yScale(d[yAxis.field]));

	if (curve) {
		line = line.curve(curve);
	}

	return groupedData.map(group => (
		<Line key={group.key} d={line(group.values)} stroke={getGroupColor(group.key)} />
	));
});

const StackedLineChart = React.memo(() => {
	const {
		xAxis,
		xScale,
		yScale,
		stackedData,
		getGroupColor,
	} = useContext(ChartContext);

	const line = d3.line()
		.defined(d => !Number.isNaN(d[1]))
		.x(d => xScale(d.data[xAxis.field]))
		.y(d => yScale(d[1]));

	return (
		<>
			{stackedData.map(group => (
				<Line key={group.key} d={line(group)} stroke={getGroupColor(group.key)} />
			))}
		</>
	);
});

const AreaChart = React.memo(() => {
	const {
		xAxis,
		yAxis,
		xScale,
		yScale,
		groupedData,
		getGroupColor,
	} = useContext(ChartContext);

	const area = d3.area()
		.defined(d => !Number.isNaN(d[yAxis.field]))
		.x(d => xScale(d[xAxis.field]))
		.y0(yScale(0))
		.y1(d => yScale(d[yAxis.field]));

	return (
		<>
			{groupedData.map(group => (
				<Area key={group.key} d={area(group.values)} fill={getGroupColor(group.key)} />
			))}
			{/* Also draw the line for visual reasons */}
			<LineChart />
		</>
	);
});

const StackedAreaChart = React.memo(() => {
	const {
		xAxis,
		xScale,
		yScale,
		stackedData,
		getGroupColor,
	} = useContext(ChartContext);

	const area = d3.area()
		.defined(d => !Number.isNaN(d[1]))
		.x(d => xScale(d.data[xAxis.field]))
		.y0(d => yScale(d[0]))
		.y1(d => yScale(d[1]));

	return (
		<>
			{stackedData.map(group => (
				<Area key={group.key} d={area(group)} fill={getGroupColor(group.key)} />
			))}
			{/* Also draw the line for visual reasons */}
			<StackedLineChart />
		</>
	);
});

const BarChart = React.memo(() => {
	const {
		xAxis,
		yAxis,
		height,
		xScale,
		yScale,
		groupedData,
		getGroupColor,
		margins,
	} = useContext(ChartContext);

	const barWidth = xScale.bandwidth() / groupedData.length;

	return groupedData.map((group, idx) => (
		group.values
			.filter(d => !Number.isNaN(d[1]))
			.map(d => (
				<Bar
					key={d[xAxis.field]}
					x={getBarX(xScale, d[xAxis.field]) + (barWidth * idx)}
					y={yScale(d[yAxis.field])}
					width={barWidth}
					height={height - margins.top - margins.bottom - (yScale(d[yAxis.field]) || 0)}
					fill={getGroupColor(group.key)}
				/>

			))
	));
});

const StackedBarChart = React.memo(() => {
	const {
		xAxis,
		xScale,
		yScale,
		stackedData,
		getGroupColor,
	} = useContext(ChartContext);

	return stackedData.map(group => (
		group
			.filter(d => !Number.isNaN(d[1]))
			.map(d => (
				<Bar
					key={d.data[xAxis.field]}
					x={getBarX(xScale, d.data[xAxis.field])}
					y={yScale(d[1])}
					width={xScale.bandwidth()}
					height={yScale(d[0]) - yScale(d[1])}
					fill={getGroupColor(group.key)}
				/>
			))
	));
});

const Legend = React.memo(() => {
	const {
		groupedData,
		keyOrder,
		getGroupName,
		getGroupColor,
	} = useContext(ChartContext);

	const sortedGroupedData = keyOrder ? groupedData.sort((a, b) => (keyOrder.indexOf(a.key) < keyOrder.indexOf(b.key) ? -1 : 1)) : groupedData;

	return (
		<LegendInner>
			{sortedGroupedData.map(group => {
				const groupName = getGroupName(group.values[0]);
				return (
					<LegendGroup key={group.key}>
						<GroupGlyph color={getGroupColor(group.key)} />
						<GroupName title={groupName}><GroupNameWrap>{groupName}</GroupNameWrap></GroupName>
					</LegendGroup>
				);
			})}
		</LegendInner>
	);
});

const Tooltip = React.memo(props => {
	const { x, y, flip, tooltipData, getTooltipAdditionalInfo } = props;
	let { tooltipValues } = props;

	const {
		xAxis,
		yAxis,
		getGroupName,
		getGroupColor,
	} = useContext(ChartContext);

	const data = Array.from(d3.group(tooltipData, d => d.data[xAxis.field]), ([key, values]) => ({ key, values }));

	if (!tooltipValues) {
		tooltipValues = [{
			value: d => d[yAxis.field],
			displayName: yAxis.displayName || yAxis.field,
			type: yAxis.type,
			customFormat: yAxis.customFormat,
			showTotal: true,
		}];
	}

	return (
		<TooltipContainer
			style={{
				left: x,
				top: y,
			}}
			$flip={flip}
		>
			{data.map(({ key, values: vals }) => (
				<React.Fragment key={key}>
					<XValue>{xAxis.customFormat ? xAxis.customFormat(key) : formatField(xAxis.type, key)}</XValue>
					<TooltipGrid style={{ gridTemplateColumns: `repeat(${tooltipValues.length + 1}, auto)` }}>
						{tooltipValues.length > 1 && (
							<TooltipGroup $small>
								<GroupValue /> {/* Empty cell as it shows GroupName in this column below */}
								{tooltipValues.map(({ displayName }, index) => (
									<GroupValue key={index}>{displayName}</GroupValue>
								))}
							</TooltipGroup>
						)}
						{vals.reverse().map(({ group, data: d }) => {
							const groupName = getGroupName(group.values[0]);

							return (
								<TooltipGroup key={group.key}>
									<GroupName title={groupName}>
										<GroupGlyph color={getGroupColor(group.key)} />
										<GroupNameWrap>{groupName}</GroupNameWrap>
									</GroupName>
									{
										tooltipValues.map(({ value: getValue, type, customFormat, small }) => (
											<GroupValue $small={small}>
												{customFormat ? customFormat(getValue(d, group)) : formatField(type, getValue(d, group))}
											</GroupValue>
										))
									}
								</TooltipGroup>
							);
						})}
						{vals.length > 1 && (
							<>
								<TooltipDivider />
								<TooltipGroup $total>
									<GroupName>{_`total`}:</GroupName>
									{
										tooltipValues.map(({ value: getValue, type, customFormat, showTotal, small }) => {
											if (!showTotal) return <GroupValue />;

											const sum = d3.sum(vals, val => getValue(val.data, val.group));
											return (
												<GroupValue $small={small}>
													{customFormat ? customFormat(sum) : formatField(type, sum)}
												</GroupValue>
											);
										})
									}
								</TooltipGroup>
							</>
						)}
					</TooltipGrid>
					{getTooltipAdditionalInfo && (
						<TooltipGroup $extra>{getTooltipAdditionalInfo(vals)}</TooltipGroup>
					)}
				</React.Fragment>
			))}
		</TooltipContainer>
	);
});

const Chart = props => {
	const { aspectRatio, getGroupColor: overrideGetGroupColor, keyOrder, apiStatus, type, showXAxisLabel, showYAxisLabel, showXAxis, showYAxis, stacked, legend, xAxis, yAxis, data, getGroupName, curve, getTooltipAdditionalInfo, tooltipValues } = props;

	/* Refs */
	const svgRef = useRef();

	/* State */
	const [hoverX, setHoverX] = useState(null);
	const [hoverHelperPositions, setHoverHelperPositions] = useState([]);
	const [tooltipData, setTooltipData] = useState(null);
	const [lastMouseMoveEvent, setLastMouseMoveEvent] = useState(null);
	const [bounds, setBounds] = useState({ left: 0, top: 0, width: 0, height: 0 });
	const [containerNode, setContainerNode] = useState(null);

	const { width, height } = bounds;

	/* Dependencies */
	const viewport = useSelectViewport();
	const containerRefCallback = useCallback(node => {
		if (node !== null) {
			setBounds(node.getBoundingClientRect());
			setContainerNode(node);
		}
	}, [viewport]);

	const subProps = useMemo(() => ({ ...props, width, height }), [props, width, height]);

	/* Initialization */
	const theme = useSelectTheme();
	const {
		groupColors = [
			theme.pokiBlue,
			theme.rose1,
			theme.green1,
			theme.yellow5,
			theme.purple1,
		],
	} = props;

	const margins = useMemo(() => getMargins(subProps), [subProps]);
	const xScale = useMemo(() => getXScale(subProps), [subProps]);
	const yScale = useMemo(() => getYScale(subProps), [subProps]);
	const getGroupColor = overrideGetGroupColor || useMemo(() => groupColorGetter(subProps, groupColors), [subProps, groupColors]);
	const groupedData = useMemo(() => getGroups(subProps), [subProps]);
	const stackedData = useMemo(() => getStackedData(subProps), [subProps]);
	const xTicks = useMemo(() => getXTicks(subProps, xScale), [subProps]);
	const yTicks = useMemo(() => getYTicks(subProps, yScale), [yScale]);
	const yRange = useMemo(() => getYRange(subProps), [subProps]);
	const distanceBetweenTicks = useMemo(() => (xTicks.length > 1 ? xScale(xTicks[1]) - xScale(xTicks[0]) : 0), [subProps]);
	const groupKeys = useMemo(() => getGroupKeys(subProps), [subProps]);

	/* Hover logic side effect */
	useEffect(() => {
		if (!containerNode || !svgRef.current) return;

		function mouseMoveHandler(e) {
			setLastMouseMoveEvent(e);

			// Determine hover X
			const svgPoint = screenSpaceToSVGSpace(svgRef.current, { x: e.clientX, y: e.clientY });

			if (svgPoint.x < margins.left) return;

			const x = Math.min(width - margins.right, Math.max(margins.left, svgPoint.x));

			// Determine snapped position

			let xOnScale;
			if (!xScale.invert) {
				const testX = x - margins.left - (distanceBetweenTicks / 2);
				// Find closest bar
				const test = xScale.domain().reduce((acc, current) => {
					const distance = Math.abs(getBarX(xScale, current) - testX);

					if (!acc.closest || acc.closestDistance > distance) {
						return {
							closest: current,
							closestDistance: distance,
						};
					}

					return acc;
				}, {
					closest: null,
					closestDistance: null,
				});

				xOnScale = test.closest;
			} else {
				xOnScale = xScale.invert(x - margins.left);
			}

			let snapX = null;
			let snapXDistance = null;

			const positions = [];
			const values = [];

			groupedData.forEach(group => {
				const idx = groupKeys.findIndex(k => k === group.key);
				const bisectData = stacked ? stackedData[idx] : group.values;

				let point;
				if (xScale.type === SCALE_TYPES.BAND) {
					point = bisectData.find(d => {
						const key = stacked ? d.data[xAxis.field] : d[xAxis.field];

						if (key instanceof Date) {
							return key.getTime() === xOnScale.getTime();
						}
						return key === xOnScale;
					});
				} else {
					const differenceBetweenTicks = Math.abs(bisectData[1][xAxis.field] - bisectData[0][xAxis.field]);
					const testX = xOnScale - (differenceBetweenTicks / 2);
					const bisect = d3.bisector(d => (stacked ? d.data[xAxis.field] : d[xAxis.field]));
					point = bisectData[bisect.left(bisectData, testX)] || bisectData[bisect.right(bisectData, testX)];
				}

				if (!point || (stacked && Number.isNaN(point[1]))) return;

				const value = stacked ? point[1] : point[yAxis.field];
				const key = stacked ? point.data[xAxis.field] : point[xAxis.field];

				const snapY = margins.top + yScale(value);
				let newSnapX = margins.left + xScale(key);

				if (xScale.type === SCALE_TYPES.BAND) {
					newSnapX += (xScale.bandwidth() / 2);
				}

				const newSnapXDistance = Math.abs(newSnapX - x);

				if (!snapX || newSnapXDistance < snapXDistance) {
					snapX = newSnapX;
					snapXDistance = newSnapXDistance;
				}

				positions.push({
					key: group.key,
					x: newSnapX,
					y: snapY,
				});

				values.push({
					group,
					data: stacked ? ({
						[xAxis.field]: point.data[xAxis.field],
						[yAxis.field]: point.data[group.key],
					}) : point,
				});
			});

			setHoverX(snapX);
			setHoverHelperPositions(positions);

			const flip = snapX > (width / 2);
			let offset = xScale.type === SCALE_TYPES.BAND ? (distanceBetweenTicks > 0 ? distanceBetweenTicks / 2 : xScale.bandwidth() * 0.2) : 10;

			if (flip) offset *= -1;

			setTooltipData({
				position: {
					x: snapX + offset,
					y: e.layerY,
				},
				flip,
				values,
			});
		}

		function mouseOutHandler() {
			setHoverX(null);
			setHoverHelperPositions([]);
			setTooltipData(null);
			setLastMouseMoveEvent(null);
		}

		// If we're still hovering, re-trigger mousemove handler
		if (hoverX !== null && lastMouseMoveEvent) {
			mouseMoveHandler(lastMouseMoveEvent);
		}

		if (!apiStatus || apiStatus.done) {
			containerNode.addEventListener('mousemove', mouseMoveHandler);
			containerNode.addEventListener('mouseout', mouseOutHandler);
		}

		return () => {
			if (!containerNode) return;

			containerNode.removeEventListener('mousemove', mouseMoveHandler);
			containerNode.removeEventListener('mouseout', mouseOutHandler);
		};
	}, [containerNode, svgRef.current, data, apiStatus, viewport, width, height]);

	/* Select correct sub component to render chart */
	let ChartComponent;
	switch (type) {
		case 'area':
			ChartComponent = stacked ? StackedAreaChart : AreaChart;
			break;

		case 'line':
			ChartComponent = stacked ? StackedLineChart : LineChart;
			break;

		case 'bar':
			ChartComponent = stacked ? StackedBarChart : BarChart;
			break;
	}

	return (
		<ChartContext.Provider
			value={useMemo(() => ({
				xAxis,
				yAxis,
				showXAxisLabel,
				showYAxisLabel,
				width,
				height,
				xScale,
				yScale,
				xTicks,
				yTicks,
				yRange,
				getGroupName,
				getGroupColor,
				keyOrder,
				groupedData,
				stackedData,
				margins,
				showXAxis,
				showYAxis,
				curve,
			}), [props, bounds])}
		>
			<ChartContainer
				ref={containerRefCallback}
				style={{
					paddingBottom: `${aspectRatio * 100}%`,
				}}
			>
				{(width === 0 || height === 0) || (apiStatus && apiStatus.pending) ? (
					<State pending>
						<ChartLoader />
					</State>
				) : apiStatus && apiStatus.error ? (
					<State error>
						<StateTitle>Problem Loading Data</StateTitle>
						<StateMessage>&quot;{apiStatus.error.message}&quot;</StateMessage>
					</State>
				) : apiStatus && apiStatus.done && data.length === 0 ? (
					<State>
						<StateTitle>No Data Yet</StateTitle>
						<StateMessage>This can take up to 24 hours, so why not go play some games and check back later!</StateMessage>
					</State>
				) : (
					<>
						<SVG
							ref={svgRef}
							viewBox={`0 0 ${width} ${height}`}
						>
							<GridLines />
							<g transform={`translate(${margins.left}, ${margins.top})`}>
								<ChartComponent />
							</g>
							<XAxis />
							<YAxis />
							{hoverX && (
								<HoverLine d={`M${hoverX},${margins.top} L${hoverX},${height - margins.bottom}`} />
							)}
							{hoverHelperPositions.map(pos => (
								<HoverHelper key={pos.key} cx={pos.x} cy={pos.y} r={3} stroke={getGroupColor(pos.key)} />
							))}
						</SVG>
						{tooltipData && tooltipData.values.length > 0 && (
							<Tooltip
								x={tooltipData.position.x}
								y={tooltipData.position.y}
								flip={tooltipData.flip}
								tooltipData={tooltipData.values}
								getTooltipAdditionalInfo={getTooltipAdditionalInfo}
								tooltipValues={tooltipValues}
							/>
						)}
					</>
				)}
			</ChartContainer>
			{legend && (
				<LegendContainer>
					{legend && (
						(!apiStatus || apiStatus.done) && data.length > 0 && (!apiStatus || !apiStatus.error) ? (
							<Legend />
						) : <>&nbsp;</> // whitespace to prevent reflow issues
					)}
				</LegendContainer>
			)}
		</ChartContext.Provider>
	);
};

Chart.defaultProps = {
	apiStatus: { done: true, pending: false, error: null },
	type: 'line',
	aspectRatio: 0.3,
	legend: false,
	showXLabel: false,
	showYLabel: false,
	showXAxis: true,
	showYAxis: true,
	data: [],
	getGroupedBy: () => { },
	getGroupName: () => { },
	xRange: null,
	margins: defaultMargins,
	getTooltipAdditionalInfo: () => { },
	tooltipValues: null,
	curve: null,
};

export default Chart;
