import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useSelectApiStatus } from '@poki/rx-api';
import { ignoreElements, tap } from 'rxjs';

import { getGameById, patchGame } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import { openModal } from 'app/src/actions/client';

import Button, { ButtonText, ButtonTextContainer } from 'app/src/components/ui/Button';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import SelectInput from 'app/src/components/input/SelectInput';
import GridContainer from 'app/src/components/ui/GridContainer';
import CheckBoxInput from 'app/src/components/input/CheckBoxInput';
import Card from 'app/src/components/ui/Card';

import _ from 'shared/copy';
import { selfServiceStages } from 'shared/vars';

const AdminGameGeneralSettingsModule = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const patchGameStatus = useSelectApiStatus(patchGame.id);
	const getGameByIdStatus = useSelectApiStatus(getGameById.id);

	const fieldsDisabled = getGameByIdStatus.pending || patchGameStatus.pending;

	const { handleSubmit, reset, control, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			internalNotes: game?.internal_notes || '',
			forceTeamCSP: game?.force_team_csp || false,
			selfServiceStage: game?.self_service_stage || '',
		},
	});

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	const onSubmit = data => {
		const { internalNotes, forceTeamCSP, selfServiceStage } = data;

		const newData = {
			internal_notes: internalNotes,
			force_team_csp: forceTeamCSP,
			self_service_stage: selfServiceStage,
		};

		dispatch(patchGame.fetch({ gameId: game.id, data: newData }, ({ success$ }) => (
			success$.pipe(
				tap(() => reset(data, { keepDirty: false })),
				ignoreElements(),
			)
		)));
	};

	const handleApproveGame = () => {
		const confirmationTitle = game.approved ? _`unapproveGameTitle` : _`approveGameTitle`;
		const confirmationDescription = game.approved ? _`unapproveGameDescription` : _`approveGameDescription`;

		dispatch(openModal({
			key: 'base-confirmation-modal',
			data: {
				title: confirmationTitle,
				description: confirmationDescription,
				onConfirm: () => {
					dispatch(patchGame.fetch({ gameId: game.id, data: { approved: !game.approved } }));
				},
			},
		}));
	};

	return (
		<Card
			title={_`gameSettings`}
			buttons={[
				game.approved ? {
					id: 'remove-approval',
					type: 'button',
					action: handleApproveGame,
					children: 'Remove Approval',
					secondary: true,
					negative: true,
				} : {
					id: 'approve-game',
					type: 'button',
					action: handleApproveGame,
					children: 'Approve Game',
					secondary: true,
					positive: true,
				},
			]}
		>
			<GridContainer cols={1}>
				{game && (
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							control={control}
							name="internalNotes"
							render={({ field: { onChange, value } }) => (
								<TextAreaInput
									height="195px"
									resize={false}
									label={_`internalNotes`}
									valueSetter={onChange}
									value={value}
									disabled={fieldsDisabled}
									errors={errors?.internalNotes?.message ? [errors?.internalNotes?.message] : []}
								/>
							)}
						/>
						<Controller
							control={control}
							name="forceTeamCSP"
							render={({ field: { onChange, value } }) => (
								<CheckBoxInput
									tooltipContent={_`forceTeamCSPTooltip`}
									text={_`forceTeamCSP`}
									disabled={fieldsDisabled}
									valueSetter={onChange}
									value={value}
									errors={errors?.forceTeamCSP?.message ? [errors?.forceTeamCSP?.message] : []}
								/>
							)}
						/>
						<Controller
							control={control}
							name="selfServiceStage"
							render={({ field: { onChange, value } }) => (
								<SelectInput
									label={_`selfServiceStage`}
									valueSetter={onChange}
									value={value}
									values={selfServiceStages}
									disabled={fieldsDisabled}
									errors={errors?.selfServiceStage?.message ? [errors?.selfServiceStage?.message] : []}
								/>
							)}
						/>
						<ButtonTextContainer>
							<Button submit disabled={fieldsDisabled || !isDirty} primary>{_`save`}</Button>
							<ButtonText warning={!patchGameStatus.pending && isDirty}>
								{patchGameStatus.pending ? _`saving` : isDirty ? _`unsavedChanges` : ''}
							</ButtonText>
						</ButtonTextContainer>
					</form>
				)}
			</GridContainer>
		</Card>
	);
};

export default AdminGameGeneralSettingsModule;
