import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { tap, ignoreElements } from 'rxjs/operators';
import { useSelectApiStatus } from '@poki/rx-api';
import { yupResolver } from '@hookform/resolvers/yup';
import { Subject } from 'rxjs';
import styled from 'styled-components';
import * as yup from 'yup';

import { registerModal } from 'app/src/modals';
import { patchInvoice } from 'app/src/epics/invoice';
import { startScreenShake } from 'app/src/actions/effects';
import { invalidCharactersRegex } from 'app/src/utils/validate';

import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';
import TextAreaInput from 'app/src/components/input/TextAreaInput';

import _ from 'shared/copy';

const Form = styled.form`
	width: 100%;
	margin-top: 16px;
`;

const Buttons = styled.div`
	margin-top: 20px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const validationSchema = yup.object().shape({
	invoice_internal_notes: yup.string().matches(invalidCharactersRegex, { message: _`invalidCharacters` }),
});

const EditInvoiceInternalNotesModal = props => {
	const { data: { invoice } } = props;

	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());

	const patchInvoiceStatus = useSelectApiStatus(patchInvoice.id);

	const { handleSubmit, control, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			invoice_internal_notes: invoice.internal_notes || '',
		},
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = data => {
		dispatch(patchInvoice.fetch({ invoiceId: invoice.id, attributes: { internal_notes: data.invoice_internal_notes } }, ({ success$ }) => (
			success$.pipe(
				tap(() => exit$.next()),
				ignoreElements(),
			)
		)));
	};

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	return (
		<Modal exit$={exit$}>
			<>
				<h2>{_`editInvoiceInternalNotes`}</h2>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name="invoice_internal_notes"
						render={({ field: { onChange, value } }) => (
							<TextAreaInput
								label={_`internalNotes`}
								value={value}
								valueSetter={onChange}
								errors={errors?.invoice_internal_notes?.message ? [errors?.invoice_internal_notes?.message] : []}
							/>
						)}
					/>
					<Buttons>
						<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
						<Button submit disabled={patchInvoiceStatus.pending || !isDirty}>{patchInvoiceStatus.pending ? _`saving` : _`save`}</Button>
					</Buttons>
				</Form>
			</>
		</Modal>
	);
};

registerModal('edit-invoice-internal-notes', EditInvoiceInternalNotesModal);
