import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';

import { useSelectUser } from 'app/src/selectors/user';
import { useSelectCachedURLTeamCode } from 'app/src/selectors/session';

export const selectTeamByCode = (state, teamCode) => (state.team.teams[teamCode] ? state.team.teams[teamCode].data : null);
export const selectTeamById = (state, teamId) => (state.team.teams[teamId] ? state.team.teams[teamId].data : null);
export const selectTeamIsCurrencyLocked = (state, team) => (state.team.teams[team.code] ? state.team.teams[team.code].meta.currency_locked : false);
export const selectTeamsList = state => (state.team.teamsList ? state.team.teamsList.data : null);
export const selectAllTeamsList = state => (state.team.allTeamsList ? state.team.allTeamsList.data : null);
export const selectUserListByTeamId = (state, teamId) => (state.team.userListByTeamId[teamId] ? state.team.userListByTeamId[teamId] : {});
export const selectTeamLastAccessedAt = (state, teamCode) => (state.team.teams[teamCode] ? state.team.teams[teamCode]?.meta?.last_accessed?.at : null);
export const selectTeamMustUpdateBilling = (state, teamCode) => (state.team.teams[teamCode] ? state.team.teams[teamCode].meta.must_update_billing : false);
export const selectTeamCurrency = (state, team) => (state.team.teams[team.code] ? state.team.teams[team.code].meta.currency : 'eur');
export const selectNewRevenueSharesByTeamId = (state, teamId) => (state.team && state.team.newRevenueShares[teamId] ? state.team.newRevenueShares[teamId].data : null);
export const selectNewRevenueSharesByGameId = (state, teamId, gameId) => (state.team && state.team.newRevenueShares[teamId] ? state.team.newRevenueShares[teamId].data.find(share => share.game_id === gameId) : null);

export const selectTeamHasBillingSettings = createSelector(
	(state, team) => selectTeamByCode(state, team?.code),

	// We're gonna assume that if the following fields are set, so are the rest.
	team => team && team.billing && team.billing.email && team.billing.name && team.billing.country && team.billing.payment_method,
);

export const useSelectTeamCurrency = team => useSelector(state => selectTeamCurrency(state, team));
export const useSelectTeamByCode = teamCode => useSelector(state => selectTeamByCode(state, teamCode));
export const useSelectTeamById = teamId => useSelector(state => selectTeamById(state, teamId));
export const useSelectTeamsList = () => useSelector(selectTeamsList);
export const useSelectAllTeamsList = () => useSelector(selectAllTeamsList);
export const useSelectUserListByTeamId = teamId => useSelector(state => selectUserListByTeamId(state, teamId));
export const useSelectNewRevenueSharesByTeamId = teamId => useSelector(state => selectNewRevenueSharesByTeamId(state, teamId));
export const useSelectNewRevenueSharesByGameId = (teamId, gameId) => useSelector(state => selectNewRevenueSharesByGameId(state, teamId, gameId));
export const useSelectTeamIsCurrencyLocked = team => useSelector(state => selectTeamIsCurrencyLocked(state, team));
export const useSelectTeamHasBillingSettings = team => useSelector(state => selectTeamHasBillingSettings(state, team));
export const useSelectTeamLastAccessedAt = teamCode => useSelector(state => selectTeamLastAccessedAt(state, teamCode));
export const useSelectTeamMustUpdateBillingSettings = teamCode => useSelector(state => selectTeamMustUpdateBilling(state, teamCode));

export const useSelectActiveTeam = () => {
	const user = useSelectUser();
	const { teamCode } = useParams();
	const cachedURLTeamCode = useSelectCachedURLTeamCode();
	const team = useSelectTeamByCode(teamCode || cachedURLTeamCode);
	const [prevTeam, setPrevTeam] = useState(team);

	useEffect(() => {
		if (team) {
			setPrevTeam(team);
		}
	}, [team]);

	return team || prevTeam || (!teamCode && user && user.team) || (user && user.team && teamCode === user.team.code && user.team);
};
