import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ignoreElements, map, merge, Subject, tap } from 'rxjs';
import styled from 'styled-components';

import { useSelectGame, useSelectGameVersionsForPlaytests } from 'app/src/selectors/game';
import { getGameById, request10kGameplays } from 'app/src/epics/game';
import { setGameChecklistStatus } from 'app/src/actions/session';
import { useSelectUser } from 'app/src/selectors/user';
import { pushEvent } from 'app/src/utils/tracking';
import { registerModal } from 'app/src/modals';

import SelectInput from 'app/src/components/input/SelectInput';
import Button from 'app/src/components/ui/Button';
import Modal from 'app/src/components/ui/Modal';

import _ from 'shared/copy';

const Buttons = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const WebFitInspectorCheck = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const WebFitInspectorCheckList = styled.ul`
	padding: 0 0 0 16px;
	margin: 0;

	li::marker {
		color: ${props => props.theme.rose1};
	}
`;

const WebFitInspectorCheckListItem = styled.li``;

const WebFitInspectorCheckListItemText = styled.div`
	color: ${props => props.theme.rose1};
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const WEB_FIT_REQUESTED = 'poki_web_fit_requested';

const STEPS_TO_CHECK = {
	'sdk-basics-gameplay-start': {
		text: 'SDK: no gameplayStart() event fired at the start of gameplay.',
		status: 'NOT_STARTED',
	},
	'sdk-basics-game-loading-finished': {
		text: 'SDK: no gameLoadingFinished() event fired when the game is fully loaded.',
		status: 'NOT_STARTED',
	},
	'mobile-optimization-playable': {
		text: 'The game is not playable on mobile',
		status: 'NOT_STARTED',
	},
};

const RequestWebFitTestModal = ({ data: { gameId } }) => {
	const dispatch = useDispatch();

	const game = useSelectGame(gameId);
	const user = useSelectUser();
	const versions = useSelectGameVersionsForPlaytests(gameId);

	const [exit$] = useState(new Subject());
	const [error, setError] = useState(null);
	const [version, setVersion] = useState(versions[0]?.value || '');
	const [hasStepsToCheck, setHasStepsToCheck] = useState(Object.values(STEPS_TO_CHECK).some(step => step.status !== 'PASSED'));

	const { inspector_checklist = game.versions.filter(v => v.id === version)[0]?.inspector_checklist } = game;

	const updatedStepStatuses = useMemo(() => (
		Object.keys(STEPS_TO_CHECK).reduce((updatedSteps, step) => {
			updatedSteps[step] = {
				...STEPS_TO_CHECK[step],
				status: inspector_checklist[step] || STEPS_TO_CHECK[step].status,
			};

			return updatedSteps;
		}, {})
	), [inspector_checklist]);

	useEffect(() => {
		if (!gameId) {
			return;
		}

		if (game.stage !== WEB_FIT_REQUESTED) {
			dispatch(setGameChecklistStatus({ gameId, level: 'webfit', step: 2, status: 'not_started' }));
		}
	}, [game]);

	// Refresh game data when the window is focused, this might be the dev
	// coming back from the Inspector after doing the checklist.
	useEffect(() => {
		if (!gameId) {
			return;
		}

		const handleFocus = () => {
			dispatch(getGameById.fetch(gameId));
		};

		window.addEventListener('focus', handleFocus);

		return () => {
			window.removeEventListener('focus', handleFocus);
		};
	}, [gameId, dispatch]);

	useEffect(() => {
		setHasStepsToCheck(Object.values(updatedStepStatuses).some(step => step.status !== 'PASSED'));
	}, [updatedStepStatuses]);

	const handleOpenInspector = () => {
		const isAdmin = user.role === 'admin' || user.role === 'read-only-admin';
		window.open(`https://inspector.poki.dev/?game=poki-${version}${isAdmin ? '&analytics=false' : ''}`, '_blank');
	};

	const handleOnConfirm = () => {
		if (!version) {
			return;
		}

		setError(null);

		if (hasStepsToCheck) {
			pushEvent('gameCreateFlow', 'requestWebFitTestIncomplete', { gameId, checklist: updatedStepStatuses });
			dispatch(setGameChecklistStatus({ gameId, level: 'webfit', step: 2, status: 'failed' }));

			return;
		}

		pushEvent('gameCreateFlow', 'requestWebFitTest', { gameId });

		dispatch(request10kGameplays.fetch({ gameId, versionId: version }, ({ success$, error$ }) => merge(
			success$.pipe(
				tap(() => exit$.next()),
				map(() => setGameChecklistStatus({ gameId, level: 'webfit', step: 2, status: 'pending' })),
			),
			error$.pipe(
				tap(({ payload: { result: { response: { errors: [err] } } } }) => {
					setError(err);
				}),
				ignoreElements(),
			),
		)));
	};

	return (
		<Modal
			exit$={exit$}
		>
			<h2>Select a version to web fit test</h2>
			<SelectInput
				label="Game version"
				values={versions}
				value={version}
				onChange={e => setVersion(e.target.value)}
			/>

			{error && <Error>{error}</Error>}

			{hasStepsToCheck && (
				<WebFitInspectorCheck>
					<strong>
						Almost there! Make sure your game meets the technical requirements listed below by using the inspector.
					</strong>
					<WebFitInspectorCheckList>
						{Object.keys(updatedStepStatuses).map(step => (
							updatedStepStatuses[step].status !== 'PASSED' && (
								<WebFitInspectorCheckListItem key={step}>
									<WebFitInspectorCheckListItemText>{updatedStepStatuses[step].text}</WebFitInspectorCheckListItemText>
								</WebFitInspectorCheckListItem>
							)
						))}
					</WebFitInspectorCheckList>
				</WebFitInspectorCheck>
			)}
			<Buttons>
				<Button secondary onClick={() => exit$.next()}>{_`cancel`}</Button>
				{hasStepsToCheck ? (
					<Button onClick={handleOpenInspector}>Open Inspector</Button>
				) : (
					<Button submit onClick={handleOnConfirm}>Send Request</Button>
				)}
			</Buttons>
		</Modal>
	);
};

registerModal('request-web-fit-test', RequestWebFitTestModal);
