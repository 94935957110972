import { combinedApiStatus, useSelectApiStatus } from '@poki/rx-api';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getEarningsPerReferrerForGameId, getTablesLastUpdatedAt } from 'app/src/epics/data';
import { useSelectDataByEpic, useSelectDataLastUpdatedAtForTable } from 'app/src/selectors/data';
import { createGetTotalEarningsPerReferrerForGameId } from 'app/src/epicCreators/data';
import { useSelectNewRevenueSharesByGameId, useSelectTeamCurrency } from 'app/src/selectors/team';
import { useSelectTheme } from 'app/src/selectors/session';
import getTimeSince from 'app/src/utils/getTimeSince';
import useDataEpic from 'app/src/hooks/useDataEpic';
import useComponentId from 'app/src/hooks/useComponentId';
import { getTeamNewRevenueShares } from 'app/src/epics/team';
import { ChartDetails, ChartDetailsInner, DetailNumber } from 'app/src/components/ChartSubComponents';

import GraphIcon from 'shared/designTokens/icons/ui/small/GraphIcon';

import Card from 'app/src/components/ui/Card';
import Stat from 'app/src/components/ui/Stat';
import Chart from 'app/src/components/ui/charts/Chart';

import _ from 'shared/copy';
import formatNumber from 'shared/utils/formatNumber';

const GameDeveloperEarningsPerReferrerChartModule = props => {
	const { game, startDate, endDate, deviceCategories, contexts, countries } = props;

	const componentId = useComponentId();

	const dispatch = useDispatch();
	const theme = useSelectTheme();

	const teamCurrency = useSelectTeamCurrency(game?.team);

	const getTotalEarningsForGameId = useDataEpic(createGetTotalEarningsPerReferrerForGameId(componentId), []);
	const getTotalEarningsPrevPeriodForGameId = useDataEpic(createGetTotalEarningsPerReferrerForGameId(`prev-${componentId}`), []);
	const gameRevenueShares = useSelectNewRevenueSharesByGameId(game?.team_id, game?.id);

	useEffect(() => {
		if (!game) return;

		const options = {
			gameId: game.id,
			teamId: game.team_id,
			from: startDate,
			to: endDate,
			currency: teamCurrency,
			deviceCategories,
			countries,
			contexts,
		};

		dispatch(getTablesLastUpdatedAt.fetch());

		dispatch(getTeamNewRevenueShares.fetch({ teamId: game.team_id }));
		dispatch(getEarningsPerReferrerForGameId.fetch(options));
		dispatch(getTotalEarningsForGameId.fetch(options));

		// Previous period
		const fromMoment = moment.utc(startDate);
		const toMoment = moment.utc(endDate);
		const dayDiff = toMoment.diff(fromMoment, 'day') + 1;
		const prevPeriodFrom = fromMoment.clone().subtract(dayDiff, 'day');
		const prevPeriodTo = toMoment.clone().subtract(dayDiff, 'day');

		dispatch(getTotalEarningsPrevPeriodForGameId.fetch({
			...options,
			from: prevPeriodFrom,
			to: prevPeriodTo,
		}));
	}, [game?.id, startDate, endDate, deviceCategories, countries, contexts]);

	const getEarningsPerReferrerForGameIdStatus = useSelectApiStatus(getEarningsPerReferrerForGameId.id);
	const getTotalEarningsForGameIdStatus = useSelectApiStatus(getTotalEarningsForGameId.id);
	const getTotalEarningsPrevPeriodForGameIdStatus = useSelectApiStatus(getTotalEarningsPrevPeriodForGameId.id);
	const getTeamNewRevenueSharesStatus = useSelectApiStatus(getTeamNewRevenueShares.id);

	const apiStatus = combinedApiStatus(getEarningsPerReferrerForGameIdStatus, getTotalEarningsForGameIdStatus, getTotalEarningsPrevPeriodForGameIdStatus, getTeamNewRevenueSharesStatus);

	const earningsForGame = useSelectDataByEpic(getEarningsPerReferrerForGameId.id).rows;
	const [totalEarnings] = useSelectDataByEpic(getTotalEarningsForGameId.id).rows;
	const [totalEarningsPrevPeriod] = useSelectDataByEpic(getTotalEarningsPrevPeriodForGameId.id).rows;

	const totalEarningsPerReferrer = useMemo(() => {
		if (!earningsForGame) return null;

		return earningsForGame.reduce((acc, row) => {
			const referrerGroup = row.referrer_group;

			if (!acc[referrerGroup]) {
				acc[referrerGroup] = 0;
			}

			acc[referrerGroup] += row.developer_earnings;

			return acc;
		}, {});
	}, [earningsForGame]);

	const lastUpdatedAt = useSelectDataLastUpdatedAtForTable('dbt_p4d_developer_earnings');

	return (
		<Card
			title={_`earnings`}
			buttons={[
				{
					id: 'referrer-last-updated',
					type: 'info',
					disabled: !lastUpdatedAt,
					icon: GraphIcon,
					children: _`dataLastUpdatedXAgo${{ timeSince: getTimeSince(moment(lastUpdatedAt)) }}`,
				},
			]}
		>
			{apiStatus.done && (
				<Stat
					value={totalEarnings?.developer_earnings || 0}
					valueDesc={_`earnedThisPeriod`}
					previous={totalEarningsPrevPeriod?.developer_earnings || 0}
					previousDesc={_`fromPreviousPeriod`}
					isCurrency={teamCurrency}
				/>
			)}
			<Chart
				type="bar"
				legend
				stacked
				apiStatus={apiStatus}
				data={earningsForGame}
				getGroupedBy={d => d.referrer_group}
				getGroupName={d => {
					switch (d.referrer_group) {
						case 'developer_domain':
							return _`developerDomain`;

						case 'organic_landing':
							return _`organicLandings`;

						case 'poki_promotion':
							return _`pokiPromotions`;

						default:
							return d.referrer_group;
					}
				}}
				keyOrder={['organic_landing', 'developer_domain', 'poki_promotion']}
				getGroupColor={v => {
					if (v === 'developer_domain') {
						return theme.dataPurple3;
					} else if (v === 'organic_landing') {
						return theme.dataPurple5;
					}

					return theme.dataYellow3;
				}}
				tooltipValues={[
					{
						value: (d, group) => {
							switch (group.key) {
								case 'organic_landing':
									return gameRevenueShares.ingame_organic_landing / 100;

								case 'developer_domain':
									return gameRevenueShares.developer_domain / 100;

								case 'poki_promotion':
									return gameRevenueShares.ingame_poki_promotion / 100;
							}

							return 0;
						},
						displayName: _`revShare`,
						type: 'percentile',
						small: true,
					},
					{
						value: d => d.developer_earnings,
						displayName: _`earnings`,
						type: teamCurrency,
						showTotal: true,
					},
				]}
				xRange={[startDate, endDate]}
				xAxis={{
					type: 'date',
					displayName: _`date`,
					field: 'date',
				}}
				yAxis={{
					type: teamCurrency,
					displayName: _`earnings`,
					field: 'developer_earnings',
				}}
			/>
			<ChartDetails>
				{apiStatus.done && totalEarningsPerReferrer && (
					<ChartDetailsInner>
						{
							totalEarningsPerReferrer.developer_domain > 0 ? (
								_`earningsPerTrafficSourceWhatDoesThisMean${{
									organicLandingShare: <DetailNumber>{Math.round(gameRevenueShares.ingame_organic_landing)}%</DetailNumber>,
									developerDomainShare: <DetailNumber>{Math.round(gameRevenueShares.developer_domain)}%</DetailNumber>,
									organicLandingEarnings: <DetailNumber $color={theme.dataPurple5}>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalEarningsPerReferrer.organic_landing, { allowDecimals: true })}</DetailNumber>,
									developerDomainEarnings: <DetailNumber $color={theme.dataPurple3}>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalEarningsPerReferrer.developer_domain, { allowDecimals: true })}</DetailNumber>,
									pokiPromotionEarnings: <DetailNumber $color={theme.dataYellow3}>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalEarningsPerReferrer.poki_promotion, { allowDecimals: true })}</DetailNumber>,
								}}`
							) : (
								_`earningsPerTrafficSourceNoDeveloperDomainWhatDoesThisMean${{
									organicLandingShare: <DetailNumber>{Math.round(gameRevenueShares.ingame_organic_landing)}%</DetailNumber>,
									organicLandingEarnings: <DetailNumber $color={theme.dataPurple5}>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalEarningsPerReferrer.organic_landing, { allowDecimals: true })}</DetailNumber>,
									pokiPromotionEarnings: <DetailNumber $color={theme.dataYellow3}>{teamCurrency === 'usd' ? '$' : '€'}{formatNumber(totalEarningsPerReferrer.poki_promotion, { allowDecimals: true })}</DetailNumber>,
								}}`
							)
						}
					</ChartDetailsInner>
				)}
			</ChartDetails>
		</Card>
	);
};

export default GameDeveloperEarningsPerReferrerChartModule;
