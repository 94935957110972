import React, { useMemo } from 'react';

import CoinIcon from 'shared/designTokens/icons/ui/small/CoinIcon';
import UsersIcon from 'shared/designTokens/icons/ui/small/UsersIcon';
import WorldIcon from 'shared/designTokens/icons/ui/small/WorldIcon';
import DashboardIcon from 'shared/designTokens/icons/ui/small/DashboardIcon';
import FeedbackIcon from 'shared/designTokens/icons/ui/small/FeedbackIcon';
import AnnouncementIcon from 'shared/designTokens/icons/ui/small/AnnouncementIcon';

import ListMenu from 'app/src/components/ui/ListMenu';

import _ from 'shared/copy';
import { LabelTypes } from 'app/src/components/ui/Label';
import { useSelectPendingInvoicesList } from 'app/src/selectors/invoice';
import { useSelectPermissions } from 'app/src/selectors/user';
import { useSelectHasAccessToGuardian } from 'app/src/selectors/guardian';
import checkPermissions from 'app/src/utils/checkPermissions';

export const adminMenuButtons = [
	{
		icon: DashboardIcon,
		to: '/admin',
		name: _`dashboard`,
		needsPermissions: [['can_read_all_games']],
	},
	{
		icon: UsersIcon,
		to: '/admin/teams',
		name: _`teams`,
		needsPermissions: [['can_read_all_teams']],
	},
	{
		icon: FeedbackIcon,
		to: '/admin/reviews',
		name: _`reviews`,
		needsPermissions: [['can_read_all_reviews']],
	},
	{
		id: 'payments',
		icon: CoinIcon,
		name: _`payments`,
		isActiveFunction: currentPath => currentPath.startsWith('/admin/payments'),
		children: [
			{
				to: '/admin/payments/invoices',
				needsPermissions: [['can_read_all_teams']],
				name: _`invoices`,
			},
			{
				to: '/admin/payments/upfront-payments',
				needsPermissions: [['can_read_all_upfront_payments']],
				name: _`upfrontPayments`,
			},
			{
				to: '/admin/payments/minimum-guarantees',
				needsPermissions: [['can_read_all_minimum_guarantees']],
				name: _`minimumGuarantees`,
			},
			{
				to: '/admin/payments/recurring-monthly-payments',
				needsPermissions: [['can_read_all_recurring_monthly_payments']],
				name: _`recurringMonthlyPayments`,
			},
			{
				to: '/admin/payments/one-off-payments',
				needsPermissions: [['can_read_all_recurring_monthly_payments']],
				name: _`oneOffPayments`,
			},
			{
				to: '/admin/payments/wise-payout',
				needsPermissions: [['can_read_wise_status']],
				name: _`finansiesBladsy`,
			},
		],
	},
	{
		icon: WorldIcon,
		to: '/admin/domains',
		name: _`domains`,
		needsPermissions: [['can_read_domain_collections']],
		isActiveFunction: currentPath => currentPath.startsWith('/admin/domains'),
	},
];

const AdminMenu = props => {
	const { collapsed } = props;

	const { meta: { total: pendingInvoicesCount } = {} } = useSelectPendingInvoicesList();
	const permissions = useSelectPermissions();
	const hasAccessToGuardian = useSelectHasAccessToGuardian();

	const buttons = useMemo(() => {
		const canEditAllTeams = checkPermissions(permissions, [['can_edit_all_teams']]);
		let calculatedButtons = [...adminMenuButtons];

		if (hasAccessToGuardian) {
			calculatedButtons.push({
				icon: AnnouncementIcon,
				to: '/admin/guardian',
				name: _`guardian`,
				needsPermissions: [],
				isActiveFunction: currentPath => currentPath.startsWith('/admin/guardian'),
			});
		}

		if (canEditAllTeams) {
			calculatedButtons = calculatedButtons.map(button => ({
				...button,
				label: pendingInvoicesCount > 0 && button.id === 'payments' ? ({
					type: LabelTypes.NUMBER,
					text: pendingInvoicesCount > 99 ? '99+' : pendingInvoicesCount,
				}) : null,
			}));
		}

		return calculatedButtons;
	}, [pendingInvoicesCount, permissions, hasAccessToGuardian]);

	return (
		<ListMenu
			buttons={buttons}
			collapsed={collapsed}
			light
		/>
	);
};

export default AdminMenu;
