import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useSelectApiStatus } from '@poki/rx-api';
import { ignoreElements, map } from 'rxjs';

import { getGameById, getGameEngineAnnotations, patchGameAnnotations } from 'app/src/epics/game';
import { startScreenShake } from 'app/src/actions/effects';
import { useSelectGameAnnotations } from 'app/src/selectors/game';
import formatSlug from 'app/src/utils/formatSlug';

import Button from 'app/src/components/ui/Button';
import Card from 'app/src/components/ui/Card';
import SelectInput from 'app/src/components/input/SelectInput';
import TextInput from 'app/src/components/input/TextInput';

import _ from 'shared/copy';

const GAME_EXCLUSIVITIES = [
	{
		value: null,
		desc: '',
	},
	{
		value: 'exclusive',
		desc: _`exclusive`,
	},
	{
		value: 'non-exclusive',
		desc: _`nonExclusive`,
	},
];

const GAME_LICENSE_FEES = [
	{
		value: 'no',
		desc: _`no`,
	},
	{
		value: 'yes',
		desc: _`yes`,
	},
];

const getGameEngines = engines => ([
	...engines.map(t => ({
		value: t,
		desc: t,
	})),
	{
		value: '',
		desc: '',
	},
	{
		value: 'new-game-engine',
		desc: _`addNewGameEngine`,
	},
]);

const GameAnnotationsModule = props => {
	const { game } = props;

	const dispatch = useDispatch();

	const [newGameEngine, setNewGameEngine] = useState('');

	const getGameEngineAnnotationsStatus = useSelectApiStatus(getGameEngineAnnotations.id);
	const patchGameStatus = useSelectApiStatus(patchGameAnnotations.id);
	const { engine: engines = [] } = useSelectGameAnnotations();

	const { handleSubmit, watch, setValue, control, formState: { errors, isDirty } } = useForm({
		defaultValues: {
			gameEngine: game?.annotations?.engine || '',
			exclusivity: game?.annotations?.exclusivity || '',
			licenseFee: game?.annotations?.license_fee || '',
		},
	});

	const gameEngine = watch('gameEngine');
	const finalEngine = newGameEngine || gameEngine;

	useEffect(() => {
		if (Object.keys(errors).length) {
			dispatch(startScreenShake());
		}
	}, [errors]);

	useEffect(() => {
		dispatch(getGameEngineAnnotations.fetch());
	}, [game]);

	const setNewGameEngineAndFormat = e => setNewGameEngine(formatSlug(e, false));

	const setNewGameEngineAndTrim = () => setNewGameEngine(formatSlug(newGameEngine, true));

	const onSubmit = data => {
		const { exclusivity, licenseFee } = data;

		const formData = {
			annotations: {
				engine: finalEngine,
				exclusivity,
				license_fee: licenseFee,
			},
		};

		dispatch(patchGameAnnotations.fetch({ gameId: game.id, data: formData }, ({ success$ }) => success$.pipe(
			map(() => {
				dispatch(getGameById.fetch(game.id));
				setValue('gameEngine', finalEngine);
			}),
			ignoreElements(),
		)));
	};

	return (
		<Card
			title={_`gameAnnotations`}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					control={control}
					name="exclusivity"
					render={({ field: { onChange, value } }) => (
						<SelectInput
							label={_`exclusivity`}
							disabled={getGameEngineAnnotationsStatus.pending || patchGameStatus.pending}
							values={GAME_EXCLUSIVITIES}
							errors={errors.gameAnnotations_exclusivity}
							value={value}
							valueSetter={onChange}
						/>
					)}
				/>
				<Controller
					control={control}
					name="licenseFee"
					render={({ field: { onChange, value } }) => (
						<SelectInput
							label={_`licenseFee`}
							disabled={getGameEngineAnnotationsStatus.pending || patchGameStatus.pending}
							values={GAME_LICENSE_FEES}
							errors={errors.gameAnnotations_licenseFee}
							value={value}
							valueSetter={onChange}
						/>
					)}
				/>
				<Controller
					control={control}
					name="gameEngine"
					render={({ field: { onChange, value } }) => (
						<SelectInput
							label={_`gameEngine`}
							disabled={getGameEngineAnnotationsStatus.pending || patchGameStatus.pending}
							values={getGameEngines(engines)}
							errors={errors.gameAnnotations_engine}
							value={value}
							valueSetter={onChange}
							placeholder={_`selectAGameEngine`}
						/>
					)}
				/>
				{gameEngine === 'new-game-engine' && (
					<TextInput
						label={_`newGameEngine`}
						disabled={patchGameStatus.pending}
						errors={errors.gameAnnotations_newEngine}
						value={newGameEngine}
						valueSetter={setNewGameEngineAndFormat}
						onBlur={setNewGameEngineAndTrim}
					/>
				)}
				<Button submit primary disabled={finalEngine === '' || patchGameStatus.pending || !isDirty}>
					{_`save`}
				</Button>
			</form>
		</Card>
	);
};

export default GameAnnotationsModule;
