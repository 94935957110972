import { InvalidParameterError } from 'app/src/errors';

const getApiUrl = (api, endpoint) => {
	if (api === 'devs') {
		// Devs API
		let url = `https://devs-api${process.env.SERVICE_ENV !== 'production' ? `-${process.env.SERVICE_ENV}` : ''}.poki.io`;

		if (process.env.SERVICE_ENV === 'local') {
			url = 'http://localhost:11337';
		}

		return `${url}${endpoint ? `/${endpoint}` : ''}`;
	} else if (api === 'auth') {
		// Auth API
		if (process.env.SERVICE_ENV === 'production') {
			return `https://auth.poki.io/auth${endpoint ? `/${endpoint}` : ''}`;
		}
		return `https://auth-acceptance.poki.io/auth${endpoint ? `/${endpoint}` : ''}`;
	} else if (api === 'realtime') {
		return `https://realtime.poki.com/${endpoint}`;
	} else if (api === 'guardian') {
		return `https://guardian.poki.io/v0/${endpoint}`;
	}

	throw new InvalidParameterError(`API ${api} does not exist`);
};

export default getApiUrl;
