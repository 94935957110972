import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { merge, of, switchMap } from 'rxjs';
import moment from 'moment';

import ExclamationIcon from 'shared/designTokens/icons/ui/tiny/ExclamationIcon';
import PreviewIcon from 'shared/designTokens/icons/ui/small/PreviewIcon';
import HandleManuallyIcon from 'shared/components/svg/HandleManuallyIcon';
import MonetizationIcon from 'shared/components/svg/MonetizationIcon';
import ApprovedIcon from 'shared/components/svg/ApprovedIcon';
import PaidIcon from 'shared/components/svg/PaidIcon';
import PencilIcon from 'shared/designTokens/icons/ui/tiny/PencilIcon';
import DocumentIcon from 'shared/designTokens/icons/ui/small/DocumentIcon';

import { openModal, openToast, uncaughtServerError } from 'app/src/actions/client';
import { patchInvoice, prepareSingleWiseInvoice } from 'app/src/epics/invoice';
import { useSelectPermissions } from 'app/src/selectors/user';
import { useSelectIsInvoicePrepared } from 'app/src/selectors/session';
import { setPreparedInvoice, setUnpreparedInvoice } from 'app/src/actions/session';
import formatNumber from 'app/src/utils/formatNumber';
import getInvoiceDueDate from 'app/src/utils/getInvoiceDueDate';
import checkPermissions from 'app/src/utils/checkPermissions';

import { ToastTypes } from 'app/src/components/ui/Toast';
import LinkStyledButton from 'app/src/components/ui/LinkStyledButton';
import TeamNameWithFlag from 'app/src/components/ui/TeamNameWithFlag';
import InvoiceDetails from 'app/src/components/InvoiceDetails';
import Table from 'app/src/components/ui/Table';
import Flag from 'app/src/components/ui/Flag';
import Tooltip from 'app/src/components/ui/Tooltip';
import Button from 'app/src/components/ui/Button';

import { dayMonthYearFormat, dayMonthYearTimeFormat } from 'shared/vars';
import _ from 'shared/copy';

const Actions = styled.div`
	display: grid;
	grid-auto-flow: column;
	width: min-content;
	gap: 12px;
	padding: 0 24px;
	padding-left: 0;
	margin-left: -4px;
`;

const Status = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 48px;
	justify-content: center;
`;

const Icon = styled.span`
	width: max-content;
	line-height: 1em;

	${props => props.onClick && `
	cursor: pointer;
	`}
`;

const PreparedIcon = styled(Icon)`
	border: 2px solid ${props => props.theme.grey3};
	border-radius: 100%;
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;
	margin-right: 0;

	[fill] {
		fill: ${props => props.theme.grey3};
	}

	${props => props.active && `
	border-color: ${props.theme.green1};
	background: ${props.theme.green1};

	[fill] {
		fill: ${props.theme.static.pureWhite};
	}
	`}
`;

const MonetizationIconWrapper = styled(PreparedIcon)`
	margin: 0;
`;

const InvoiceNumberAndDate = styled.div`
	div {
		font-size: 12px;
	}
`;

const Name = styled.span``;

const Desc = styled.span`
	color: ${props => props.theme.grey3};
	font-size: 12px;

	${props => props.inactive && `
	color: ${props.theme.grey5};
	`}
`;

const BillingName = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 12px;
`;

const ToBePaid = styled.div`
	display: flex;
	flex-direction: column;
`;

const ToBePaidAmountWithFlag = styled.div`
	display: flex;
`;

const Total = styled.span`
${props => props.strikethrough && `
text-decoration: line-through;
`}
`;

const DueDate = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 12px;

	${props => props.$overdue && `
	color: ${props.theme.rose1};
	`}
`;

const StyledPreviewIcon = styled(PreviewIcon)`
	margin: 0;
`;

const TeamNameWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	${Icon}	{
		margin-top: 4px;
	}

	[fill] {
		fill: ${props => props.theme.yellow3};
	}
`;

const RecipientWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const NoInvoiceInternalNotes = styled.span`
	font-style: italic;
`;

const InvoiceInternalNotes = styled.div`
	white-space: pre-wrap;
	max-height: 250px;
	overflow: auto;
`;

const InvoiceInternalNotesIcon = styled(DocumentIcon)`
	position: relative;
	left: 10px;
	margin-left: -18px;

	${props => (!props.$notesAvailable ? `
	[fill] {
		fill: ${props.theme.grey5};
	}
	` : '')}
`;

const AdminInvoicesTable = props => {
	const { isLoading, invoices, readOnly = false, sortField, sortDirection, setSort, setPagination, page, perPage, totalItems, filters, activeFilters, setActiveFilters } = props;

	const dispatch = useDispatch();
	const permissions = useSelectPermissions();

	const createApproveHandler = invoice => () => {
		dispatch(patchInvoice.fetch({
			invoiceId: invoice.id,
			attributes: {
				status: 'approved',
			},
		}));
	};

	const createHandleManuallyHandler = invoice => () => {
		dispatch(patchInvoice.fetch({
			invoiceId: invoice.id,
			attributes: {
				status: 'manual',
			},
		}));
	};

	const createPaidHandler = invoice => () => {
		dispatch(patchInvoice.fetch({
			invoiceId: invoice.id,
			attributes: {
				paid: true,
			},
		}));
	};

	const createPrepareInWiwseConfirmationHandler = invoice => () => {
		dispatch(openModal({
			key: 'base-confirmation-modal',
			data: {
				title: 'Are you sure you want to prepare this invoice?',
				description: `Invoice #${invoice.number} will be prepared in Wise.`,
				onConfirm: () => {
					dispatch(prepareSingleWiseInvoice.fetch({ invoiceId: invoice.id }, ({ success$, error$ }) => (
						merge(
							success$.pipe(
								switchMap(() => of(
									setPreparedInvoice({ id: invoice.id }),
									openToast({ body: _`invoicePreparedInWiseSuccessfully`, toastType: ToastTypes.DEFAULT }),
								)),
							),
							error$.pipe(
								switchMap(action => of(uncaughtServerError({ action }))),
							),
						)
					)));
				},
			},
		}));
	};

	const handleInvoicePreview = invoice => {
		dispatch(openModal({ key: 'admin-preview-invoice', data: { invoice } }));
	};

	return (
		<Table
			isLoading={isLoading}
			items={invoices}
			setSort={setSort}
			sortField={sortField}
			sortDirection={sortDirection}
			setPagination={setPagination}
			page={page}
			perPage={perPage}
			totalItems={totalItems}
			filters={filters}
			activeFilters={activeFilters}
			setActiveFilters={setActiveFilters}
			details={({ item }) => <InvoiceDetails invoice={item} />}
			columns={[
				{
					title: _`invoiceNumber`,
					content: ({ item }) => (
						<InvoiceNumberAndDate>
							<LinkStyledButton onClick={() => handleInvoicePreview(item)}>
								{item.number || 'DRAFT'}
							</LinkStyledButton>
							<div>{_`createdOn`}: {moment(item.created_at * 1000).format(dayMonthYearFormat)}</div>
						</InvoiceNumberAndDate>
					),
					sortField: 'created_at',
					defaultSortDirection: 1,
				},
				{
					title: '',
					mobileTitle: _`invoiceInternalNotes`,
					width: 'min-content',
					content: ({ item }) => (
						<Tooltip
							title={_`invoiceInternalNotes`}
							body={item.notes ? <InvoiceInternalNotes>{item.notes}</InvoiceInternalNotes> : <NoInvoiceInternalNotes>{_`noInvoiceInternalNotes`}</NoInvoiceInternalNotes>}
							icon={(
								<PencilIcon
									onClick={event => {
										dispatch(openModal({ key: 'edit-invoice-internal-notes', data: { invoice: item } }));
										event.stopPropagation();
									}}
								/>
							)}
						>
							<InvoiceInternalNotesIcon $notesAvailable={!!item.notes} />
						</Tooltip>
					),
					mobileContent: ({ item }) => (
						<Button
							secondary
							onClick={event => {
								dispatch(openModal({ key: 'edit-invoice-internal-notes', data: { invoice: item } }));
								event.stopPropagation();
							}}
						>
							{_`editInvoiceInternalNotes`}
						</Button>
					),
				},
				{
					title: _`recipient`,
					content: ({ item }) => (
						<RecipientWrapper>
							<TeamNameWrapper>
								<TeamNameWithFlag
									team={item.team}
									overrideName={item.recipient_name}
									overrideLink={`/${item.team.code}/settings/billing`}
								/>
								{item.team.billing.public_notes && (
									<Icon title={item.team.billing.public_notes}>
										<ExclamationIcon />
									</Icon>
								)}
							</TeamNameWrapper>
							{(item.recipient_name !== item.billing_name) && <BillingName>{item.billing_name}</BillingName>}
						</RecipientWrapper>
					),
					sortField: 'recipient_name',
				},
				{
					title: _`toBePaid`,
					content: ({ item }) => {
						const dueDate = getInvoiceDueDate(item);
						const dayDiff = dueDate.diff(moment(), 'days');
						return (
							<ToBePaid>
								<ToBePaidAmountWithFlag>
									<Flag countryCode={item.billing_country} />
									<Total strikethrough={item.status === 'manual'}>{item.currency === 'usd' ? '$' : '€'}{formatNumber(Number(item.total / 100), { allowDecimals: true })}</Total>
								</ToBePaidAmountWithFlag>
								{!readOnly && (
									dayDiff < 0
										? <DueDate title={dueDate.format(dayMonthYearFormat)} $overdue>{Math.abs(dayDiff)} days overdue</DueDate>
										: <DueDate>Due in <span title={dueDate.format(dayMonthYearFormat)}>{dayDiff} days</span></DueDate>
								)}
							</ToBePaid>
						);
					},
					sortField: 'total',
				},
				(!readOnly && checkPermissions(permissions, [['can_edit_all_teams']])) && {
					title: _`actions`,
					content: ({ item }) => {
						const flags = item.team.flags ? item.team.flags.split(',') : [];
						const mustUpdateBilling = flags.includes('must_update_billing');

						const actionDisabled = isLoading || (item.status !== 'open');
						const approveDisabled = actionDisabled || !item.billing_email || mustUpdateBilling;

						return (
							<Actions>
								<Icon
									title={!approveDisabled ? _`approveInvoice` : undefined}
									onClick={!approveDisabled ? createApproveHandler(item) : undefined}
								>
									<ApprovedIcon active={item.status === 'approved'} disabled={approveDisabled} />
								</Icon>
								<Icon
									title={!actionDisabled ? _`handleManually` : undefined}
									onClick={!actionDisabled ? createHandleManuallyHandler(item) : undefined}
								>
									<HandleManuallyIcon active={item.status === 'manual'} disabled={actionDisabled} />
								</Icon>
							</Actions>
						);
					},
				},
				{
					title: _`status`,
					content: ({ item }) => {
						if (item.status === 'approved') {
							return (
								<Status>
									<Name>{_`approved`}</Name>
									<Desc inactive={!item.sent_at}>{item.sent_at ? _`sentAtX${{ dateTime: moment(item.sent_at * 1000).format(dayMonthYearTimeFormat) }}` : _`unsent`}</Desc>
								</Status>
							);
						} else if (item.status === 'manual') {
							return (
								<Status>
									<Name>{_`handledManually`}</Name>
									{item.payment_method === 'wise' && (
										<Desc>{_`wiseTransfer`}</Desc>
									)}
								</Status>
							);
						}

						return <Status />;
					},
					sortField: 'status',
					defaultSortDirection: 1,
				},
				!readOnly && {
					title: _`preparedAndPaid`,
					width: 'max-content',
					content: ({ item }) => {
						const prepared = useSelectIsInvoicePrepared(item.id);
						const paidEnabled = !item.paid_at && !isLoading && (item.status === 'manual' || (item.status === 'approved' && item.sent_at));
						const canPrepareSingleInvoice = checkPermissions(permissions, [['can_prepare_single_invoice']]);

						return (
							item.payment_method !== 'wise' || (item.payment_method === 'wise' && item.status === 'manual') ? (
								<Actions>
									<PreparedIcon
										title={_`markAsPrepared`}
										onClick={() => {
											if (!prepared) {
												dispatch(setPreparedInvoice({ id: item.id }));
											} else {
												dispatch(setUnpreparedInvoice({ id: item.id }));
											}
										}}
										active={prepared}
									>
										<StyledPreviewIcon />
									</PreparedIcon>
									<Icon
										title={paidEnabled ? _`markAsPaid` : undefined}
										onClick={paidEnabled ? createPaidHandler(item) : undefined}
									>
										<PaidIcon active={item.paid_at} disabled={!paidEnabled} />
									</Icon>
								</Actions>
							) : (
								<Status>
									{((item.sent_at && !item.wise_state) && !prepared) && canPrepareSingleInvoice ? (
										<MonetizationIconWrapper
											title={_`preapreInWise`}
											onClick={createPrepareInWiwseConfirmationHandler(item)}
										>
											<MonetizationIcon />
										</MonetizationIconWrapper>
									) : (
										item.wise_latest_error && item.wise_latest_error.length > 0 ? <Name>{_`wiseError`}</Name> : <Name>{_`wiseTransfer`}</Name>
									)}
								</Status>
							)
						);
					},
					sortField: 'payment_method',
				},
				readOnly && {
					title: _`markedAsPaidAt`,
					width: 'max-content',
					content: ({ item }) => item.paid_at && <>{moment(item.paid_at * 1000).format(dayMonthYearTimeFormat)}</>,
					sortField: 'paid_at',
				},
			]}
		/>
	);
};

export default AdminInvoicesTable;
