import React, { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import lodash from 'lodash';

import FirstIcon from 'shared/designTokens/icons/ui/small/FirstIcon';
import LastIcon from 'shared/designTokens/icons/ui/small/LastIcon';
import ChevronDownIcon from 'shared/designTokens/icons/ui/small/ChevronDownIcon';
import ChevronLeftIcon from 'shared/designTokens/icons/ui/small/ChevronLeftIcon';
import ChrevonRightIcon from 'shared/designTokens/icons/ui/small/ChevronRightIcon';

import Link from 'app/src/components/Link';
import { motionSpeed02, motionOut, isMobile } from 'shared/vars';
import _ from 'shared/copy';
import SelectInput from 'app/src/components/input/SelectInput';
import TriggerableMultiSelectInput from 'app/src/components/input/TriggerableMultiSelectInput';
import TableLoader from 'app/src/components/ui/TableLoader';
import EmptyMessage, { EmptyMessageContainer } from 'app/src/components/ui/EmptyMessage';
import { InvalidPropError } from 'app/src/errors';
import FilterTextInput from 'app/src/components/input/FilterTextInput';

const PageSelect = styled(SelectInput)`
	flex-shrink: 0;
`;

const StatusContainer = styled.div`
	height: 180px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: ${props => props.theme.grey3};
	font-size: 16px;
	line-height: 20px;

	${EmptyMessageContainer} {
		margin-top: 0;
	}
`;

const Grid = styled.div`
	display: grid;
	position: relative;
	grid-template-columns: ${props => props.templateColumns};

	${StatusContainer} {
		height: 100%;
		width: 100%;
		position: absolute;
		background: ${props => props.theme.pureWhite}bb;
		z-index: 3;
	}
`;

const ExpandIcon = styled(ChevronDownIcon)`
	path {
		fill: ${props => props.theme.grey5};
	}
`;

const SortArrow = styled(ChevronDownIcon)`
	width: auto;
	margin-left: 8px;

	path {
		fill: ${props => props.theme.grey5};
	}

	${props => props.$inverted && `
	transform: scale(1, -1);
	`}
`;

const inlineColStyle = css`
	background: ${props => props.theme.pureWhite};
	color: ${props => props.theme.grey3};
	border-bottom: ${props => (!(props.$last && props.$lastcolumn) ? `1px solid ${props.theme.grey7}` : '')};
	padding: 8px 16px;
	display: flex;
	align-items: center;
	font-weight: bold;
`;

const InlineCol = styled.div`
	${inlineColStyle}
`;

const LinkInlineCol = styled(Link)`
	${inlineColStyle}
	transition: ${motionSpeed02} ${motionOut};
`;

const ClickToOpenInlineCol = styled.div`
	${inlineColStyle}
	cursor: pointer;
	transition: ${motionSpeed02} ${motionOut};

	${props => props.$detailsActive && `
	background: ${props.theme.grey7};
	`}
`;

const ItemDivider = styled.div`
	height: 24px;
	grid-column-start: 1;
	grid-column-end: 3;
	background: ${props => props.theme.grey9};
	border-bottom: 1px solid ${props => props.theme.grey7};
`;

const Col = styled.div`
	background: ${props => props.theme.grey9};
	font-size: 16px;
	font-weight: bold;
	color: ${props => props.theme.grey3};
	border-bottom: 1px solid ${props => props.theme.grey7};
	padding: 16px 32px;
	padding-right: 0;
	display: flex;
	align-items: center;

	${props => (!isMobile && props.$lastcolumn ? `
	padding-right: 32px;
	` : '')}

	${props => (!isMobile && props.$firstcolumn ? `
	padding-left: 24px;
	` : '')}

	${props => props.onClick && `
	cursor: pointer;

	[fill] {
		fill: ${props.theme.grey5};
	}

	${props.$active ? `
		color: ${props.theme.pokiBlue};

		[fill] {
			fill: ${props.theme.pokiBlue};
		}
	` : ''}
	`}

	${props => props.$hasTitle && `
	text-decoration: underline;
	text-decoration-style: dotted;
	`}
`;

const itemStyle = css`
	position: relative;
	background: ${props => props.theme.pureWhite};
	padding: 16px 32px;
	padding-right: 0;
	display: flex;
	flex-direction: column;
	justify-content: ${props => (props.$itemAlign === 'top' ? 'flex-start' : props.$itemAlign === 'bottom' ? 'flex-end' : 'center')};
	font-size: 16px;
	min-width: 0;
	overflow-wrap: anywhere;

	border-bottom: ${props => (
		(isMobile && !(props.$last && props.$lastcolumn)) || (!isMobile && !props.$last && !props.$detailsActive)
			? `1px solid ${props.theme.grey7}`
			: 'none'
	)};

	${props => (!isMobile && props.$lastcolumn ? `
	padding-right: 32px;
	` : '')}

	${props => (!isMobile && props.$firstcolumn ? `
	padding-left: 24px;
	` : '')}

	${!isMobile ? `
	&:last-of-type {
		padding-right: 32px;
	}
	` : `
	padding-left: 16px;
	padding-right: 16px;
	`}
`;

const Item = styled.div`
	${itemStyle}
`;

const LinkItem = styled(Link)`
	${itemStyle}
	transition: ${motionSpeed02} ${motionOut};
`;

const ItemInner = styled.div`
	position: relative;
	z-index: 2;
	display: flex;
`;

const Total = styled.div`
	background: ${props => props.theme.grey7};
	padding: 16px 32px;
	padding-right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 16px;
	min-width: 0;
	font-weight: bold;
	border-top: 1px solid ${props => props.theme.grey7};

	${props => (!isMobile && props.$lastcolumn ? `
	padding-right: 32px;
	` : '')}

	${props => (!isMobile && props.$firstcolumn ? `
	padding-left: 24px;
	` : '')}

	${isMobile && `
		padding-left: 16px;
		padding-right: 16px;
	`}
`;

const ClickToOpenItem = styled.div`
	${itemStyle}
	cursor: pointer;
	transition: ${motionSpeed02} ${motionOut};

	${props => props.$detailsActive && `
	background: ${props.theme.grey7};
	`}

	${props => props.$detailsActive && `
	${ExpandIcon} {
		transform: scale(1, -1);
	}
	`}
`;

const ItemDetails = styled.div`
	grid-column: 1/-1;
	padding: 0 16px 16px;
	background: ${props => props.theme.grey7};

	${Col} {
		background: ${props => props.theme.pureWhite};
		padding-bottom: 8px;
	}
`;

const ItemDetailsInner = styled.div`
	background: ${props => props.theme.pureWhite};
	border-radius: 8px;
	overflow: hidden;
`;

const Pagination = styled.div`
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px;
	margin-top: auto;
	flex-shrink: 0;
`;

const PaginationSpacer = styled.div`
	width: 100%;
`;

const StyledPageButton = styled.div`
	min-width: 36px;
	padding: 0 6px;
	height: 36px;
	color: ${props => props.theme.grey5};
	background: ${props => props.theme.grey7};
	border-radius: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;
	flex-shrink: 0;

	svg path[fill] {
		fill: ${props => props.theme.grey5};
	}

	${props => !props.$dots && !props.$disabled && !props.$active && `
	cursor: pointer;

	&:hover {
		background: ${props.theme.pokiBlue};
		color: ${props.theme.static.pureWhite};

		svg path[fill] {
			fill: ${props.theme.static.pureWhite};
		}
	}
	`}

	${props => props.$disabled && `
	background: none;
	color: ${props.theme.grey5};

	svg path[fill] {
		fill: ${props.theme.grey5};
	}
	`}

	${props => props.$active && `
	background: none;
	color: ${props.theme.denimBlue};

	svg path[fill] {
		fill: ${props.theme.denimBlue};
	}
	`}

	& + & {
		margin-left: 8px;
	}

	${props => props.$dots && `
	background: none;

	&:after {
		content: '...';
	}
	`}
`;

const Container = styled.div`
	position: relative;

	${props => !props.noHeader && `
		min-height: 100px;
	`}

	${props => props.compact && `
	${Item}, ${LinkItem}, ${ClickToOpenItem}, ${Total} {
		padding-top: 8px;
		padding-bottom: 8px;
	}
	`}

	${props => props.scrollable && `
		overflow: auto;
		max-height: 200px;
	`}
`;

const FilterContainer = styled.div`
	display: flex;
	padding: 12px 24px;
	border-bottom: 1px solid ${props => props.theme.grey7};
	position: relative;
	flex-wrap: wrap;
`;

const Filter = styled.div`
	max-width: 100%;
	margin-bottom: 8px;
`;

const FilterSelect = styled.div`
	margin-right: 8px;
	display: flex;
	align-items: center;
	background: ${props => props.theme.grey7};
	border: 1px solid ${props => props.theme.grey5};
	font-size: 16px;
	color: ${props => props.theme.denimBlue};
	padding: 0 8px 0 16px;
	line-height: 36px;
	height: 36px;
	border-radius: 8px;
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	font-family: 'Proxima Nova';
	cursor: pointer;
	color: ${props => props.theme.grey3};
	white-space: nowrap;

	&:hover {
		background-color: ${props => props.theme.grey9};
	}

	svg {
		margin-left: 8px;

		[fill] {
			fill: ${props => props.theme.grey5};
		}
	}
`;

const FilterValues = styled.span`
	margin-left: 4px;
	color: ${props => props.theme.denimBlue};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
`;

const Row = styled.div`
	display: contents;

	${props => props.odd && !isMobile && `
	> * {
		background: ${props.theme.grey9}88;
	}
	`}

	${props => props.$active && `
	> * {
		background: ${props.theme.grey7};
	}
	`}

	${props => props.hasHover && `
	&:hover > * {
		background: ${props.theme.grey7};
	}
	`}
`;

const GroupHeader = styled.div`
	grid-column: 1 / -1;  /* Ensures the GroupHeader spans across all columns */
	display: flex;
	align-items: center;
	gap: 8px;

	padding: 16px;
	font-size: 16px;
	color: ${props => props.theme.denimBlue};
	border-bottom: 1px solid ${props => props.theme.grey7};

	${isMobile && css`
		background: ${props => props.theme.grey9};
		border: none;
		padding: 16px 16px 0;
	`}

`;

const empty = [];

const PageButton = ({ ...props }) => {
	if (props.$disabled) props.onClick = undefined;

	return <StyledPageButton {...props} />;
};

const Table = props => {
	const { isLoading = false, itemAlign = 'center', compact, noHeader, scrollable, columns: inputColumns, itemLink, page, perPage, setPagination, autoPaging, sortField, sortDirection, setSort, setActiveFilters, details: Details, totals, filters = [], activeFilters, onToggleDetailsActive, groupBy, groupOrder, groupByFormatter } = props;

	let { items, totalItems } = props;

	if (!items) items = empty; // We need to re-use this array otherwise we end up in an endless side effect repeat

	if (itemLink && Details) {
		throw new InvalidPropError('A table cannot have both an itemLink and a Details prop');
	}

	const [firstIndexPerCategory, setFirstIndexPerCategory] = useState();
	const [itemsPerGroup, setItemsPerGroup] = useState();

	const columns = useMemo(() => {
		const cols = inputColumns.filter(c => !!c).map(col => ({
			width: 'auto',
			defaultSortDirection: -1,
			...col,
		}));

		if (Details) {
			cols.push({
				title: '',
				width: 'min-content',
				hideOnMobile: true,
				content: () => <ExpandIcon />,
			});
		}

		return cols;
	}, [inputColumns, Details]);

	const totalsWithValues = useMemo(() => {
		if (!totals || totals.length === 0) return null;

		if (isMobile) {
			return totals.reduce((acc, curr) => {
				if (!curr.value) {
					acc.push(curr.format());
					acc.push(null);

					return acc;
				}

				const column = columns[curr.colIdx];
				acc.push(column.title);

				const result = curr.value ? items.reduce((sum, item) => sum + curr.value(item), 0) : null;

				if (curr.format) {
					acc.push(curr.format(result));
				} else {
					acc.push(result);
				}

				return acc;
			}, []);
		}

		return columns.map((column, colIdx) => {
			const total = totals.find(t => t.colIdx === colIdx);

			if (!total) return null;

			const result = total.value ? items.reduce((sum, item) => sum + total.value(item), 0) : null;

			if (total.format) {
				return total.format(result);
			}

			return result;
		});
	}, [items, columns]);

	const [detailsActive, setDetailsActive] = useState();

	// Used for automated pagination
	if (typeof totalItems === 'undefined') {
		totalItems = items.length;
	}

	const paginatedItems = useMemo(() => {
		if (!autoPaging) {
			return items;
		}
		return items.slice((page - 1) * perPage, page * perPage);
	}, [items, perPage, page, autoPaging]);

	const groupedItems = useMemo(() => {
		if (!groupBy) {
			return paginatedItems;
		}

		const itemsPerGroupTemp = {};
		const firstIndexPerCategoryTemp = groupOrder.reduce((acc, category) => {
			acc[category] = -1;
			return acc;
		}, {});

		const sortedItems = paginatedItems.sort((a, b) => groupOrder.indexOf(groupBy(a)) - groupOrder.indexOf(groupBy(b)));

		sortedItems.reduce((acc, item, index) => {
			const category = groupBy(item);

			itemsPerGroupTemp[category] = (itemsPerGroupTemp[category] || 0) + 1;

			if (firstIndexPerCategoryTemp[category] === -1) {
				firstIndexPerCategoryTemp[category] = index;
			}

			acc.push(item);
			return acc;
		}, []);

		setFirstIndexPerCategory(firstIndexPerCategoryTemp);
		setItemsPerGroup(itemsPerGroupTemp);

		return sortedItems;
	}, [paginatedItems, groupBy, groupOrder]);

	const getDetailsActive = item => detailsActive === item;

	const toggleDetailsActive = item => () => {
		const isCurrentlyActive = getDetailsActive(item);
		const selection = window.getSelection().toString();

		if (selection.length > 0) return;

		if (getDetailsActive(item)) {
			setDetailsActive(null);
		} else {
			setDetailsActive(item);
		}

		if (onToggleDetailsActive) {
			onToggleDetailsActive(!isCurrentlyActive, item);
		}
	};

	// We add minmax everywhere because without min width 0 grid items act weeeeeird
	const templateColumns = isMobile ? 'minmax(0, auto) minmax(0, auto)' : columns.map(col => `minmax(0, ${col.width})`).join(' ');

	const setPage = num => {
		if (setPagination) setPagination(num);
	};

	const ItemComponent = Details ? ClickToOpenItem : itemLink ? LinkItem : Item;
	const InlineColComponent = Details ? ClickToOpenInlineCol : itemLink ? LinkInlineCol : InlineCol;

	const totalPages = Math.ceil(totalItems / perPage);

	const pageButtons = useMemo(() => {
		const pageBuffer = 4;

		// First calculate the main buttons
		const result = [];
		const leftOffset = Math.min(page - pageBuffer - 1, 0);
		const rightOffset = Math.max((page + pageBuffer) - totalPages, 0);

		for (let i = -pageBuffer; i <= pageBuffer; i++) {
			result.push(page + i - leftOffset - rightOffset);
		}

		const uniqueFiltered = lodash.uniq(result.filter(p => p > 0 && p <= totalPages));

		// Then replace some buttons with first/last and dots if necessary
		if (page > pageBuffer && totalPages > ((pageBuffer * 2) + 1)) {
			uniqueFiltered[0] = 1;
			uniqueFiltered[1] = null; // Dots
		}

		if (page < (totalPages - pageBuffer) && totalPages > ((pageBuffer * 2) + 1)) {
			uniqueFiltered[uniqueFiltered.length - 1] = totalPages;
			uniqueFiltered[uniqueFiltered.length - 2] = null; // Dots
		}

		return uniqueFiltered;
	}, [page, perPage, groupedItems]);

	useEffect(() => {
		if (!isLoading && items.length === 0 && page !== 1) {
			// Fail-safe back to first page if we have zero items
			setPage(1);
		}
	}, [isLoading, items.length, page]);

	if (groupedItems === null) return null;

	const filterComponent = filters.length > 0 && (
		<FilterContainer>
			{filters.map(filter => {
				const key = `table-filters-${filter.field}`;

				if (filter.type === 'multiselect') {
					const [...values] = filter.values;
					const setValue = value => {
						setPage(1);

						setActiveFilters(({ ...data }) => {
							if (value.length === 0) {
								delete data[filter.field];
							} else {
								data[filter.field] = value;
							}

							return data;
						});
					};

					return (
						<Filter key={key}>
							<TriggerableMultiSelectInput
								name={key}
								title={filter.title}
								trigger={onToggle => (
									<FilterSelect
										onClick={onToggle}
									>
										{filter.title}:
										<FilterValues>
											{
												activeFilters && activeFilters[filter.field] && activeFilters[filter.field].length < values.length ? (
													activeFilters[filter.field].map(value => values.find(f => f.value === value)?.desc).filter(v => v).join(', ')
												) : _`all`
											}
										</FilterValues>
										<ChevronDownIcon />
									</FilterSelect>
								)}
								countLabel={filter.countLabel}
								values={values}
								value={activeFilters ? activeFilters[filter.field] : null}
								valueSetter={setValue}
								cantSelectNone
							/>
						</Filter>
					);
				} else if (filter.type === 'search') {
					const setValue = value => {
						setPage(1);

						setActiveFilters(({ ...data }) => {
							if (value === '') {
								delete data[filter.field];
							} else {
								data[filter.field] = value;
							}

							return data;
						});
					};

					return (
						<Filter key={key}>
							<FilterTextInput
								name={key}
								icon={filter.icon}
								small={filter.small}
								placeholder={filter.title}
								value={(activeFilters && activeFilters[filter.field]) || ''}
								valueSetter={setValue}
							/>
						</Filter>
					);
				}

				throw new InvalidPropError(`${filter.type} is not a valid Table.filter.type`);
			})}
		</FilterContainer>
	);

	return (
		<Container compact={compact} noHeader={noHeader} scrollable={scrollable}>
			{isLoading && items.length === 0 ? (
				<StatusContainer>
					<TableLoader />
				</StatusContainer>
			) : items.length === 0 ? (
				<>
					{filterComponent}
					<StatusContainer>
						<EmptyMessage
							type="table"
						/>
					</StatusContainer>
				</>
			) : (
				<>
					{filterComponent}
					<Grid templateColumns={templateColumns}>
						{isLoading && (
							<StatusContainer>
								{_`loading`}
							</StatusContainer>
						)}
						{!isMobile && !noHeader && columns.map((col, colIdx) => {
							const active = col.sortField && sortField === col.sortField;

							return (
								<Col
									key={`col-${col.title || colIdx}`}
									onClick={col.sortField && setSort && (
										() => {
											setSort({
												field: col.sortField,
												direction: active ? sortDirection * -1 : col.defaultSortDirection,
											});
											setPage(1);
										}
									)}
									title={col.longTitle}
									$hasTitle={!!col.longTitle}
									$active={active}
									$lastcolumn={colIdx === columns.length - 1}
									$firstcolumn={colIdx === 0}
								>
									{col.title}
									{col.sortField && setSort && (
										<SortArrow
											$inverted={(active && sortDirection > 0) || (!active && typeof col.defaultSortDirection !== 'undefined' && col.defaultSortDirection > 0)}
										/>
									)}
								</Col>
							);
						})}
						{groupedItems.map((item, itemIdx) => {
							const itemDetailsActive = Details && getDetailsActive(item);
							const filteredCols = columns.filter(col => !(isMobile && col.hideOnMobile));

							const category = groupBy ? groupBy(item) : null;
							const isFirstItemInGroup = groupBy && firstIndexPerCategory[category] === itemIdx;

							return (
								<React.Fragment key={`grouped-item-${itemIdx}`}> {/* eslint-disable-line react/no-array-index-key */}
									{isFirstItemInGroup && (
										<GroupHeader>
											<strong>{groupByFormatter(category)}</strong>
											{itemsPerGroup[category]} item(s)
										</GroupHeader>
									)}
									{isMobile && (
										<ItemDivider
											key={`divider-${page}-${itemIdx}`} /* eslint-disable-line react/no-array-index-key */
										/>
									)}
									<Row
										key={`row-${page}-${itemIdx}`} /* eslint-disable-line react/no-array-index-key */
										hasHover={itemLink || Details}
										odd={(itemIdx + 1) % 2 === 0}
										$active={itemDetailsActive}
									>
										{filteredCols.map((col, colIdx) => (
											<React.Fragment key={`col-fragment-${colIdx}`}> {/* eslint-disable-line react/no-array-index-key */}
												{isMobile && (
													<InlineColComponent
														to={itemLink && itemLink(item)}
														onClick={Details && toggleDetailsActive(item)}
														$detailsActive={itemDetailsActive}
														$last={itemIdx === groupedItems.length - 1}
														$lastcolumn={colIdx === filteredCols.length - 1}
														$firstcolumn={colIdx === 0}
														key={`inline-${col.title}`}
													>
														{col.mobileTitle || col.title}
													</InlineColComponent>
												)}
												<ItemComponent
													to={itemLink && itemLink(item)}
													onClick={Details && toggleDetailsActive(item)}
													$detailsActive={itemDetailsActive}
													$last={itemIdx === groupedItems.length - 1}
													$lastcolumn={colIdx === filteredCols.length - 1}
													$firstcolumn={colIdx === 0}
													$itemAlign={itemAlign}
													key={`item-${col.title}`}
												>
													<ItemInner>
														{isMobile && col.mobileContent ? <col.mobileContent item={item} $detailsActive={itemDetailsActive} /> : <col.content item={item} itemDetailsActive={itemDetailsActive} />}
													</ItemInner>
												</ItemComponent>
											</React.Fragment>
										))}
										{itemDetailsActive && (
											<ItemDetails>
												<ItemDetailsInner>
													<Details item={item} idx={itemIdx} page={page} />
												</ItemDetailsInner>
											</ItemDetails>
										)}
									</Row>
								</React.Fragment>
							);
						})}
						{totalsWithValues?.length > 0 && isMobile && <ItemDivider />}
						{totalsWithValues?.map((t, idx) => (
							<Total
								key={`totals-${t}`}
								$firstcolumn={idx === 0}
								$lastcolumn={idx === totalsWithValues.length - 1}
							>
								{t !== null && t}
							</Total>
						))}
					</Grid>
					{perPage && totalPages > 1 && pageButtons.length > 0 && (
						<Pagination>
							{/* First/Prev navigation buttons */}
							{!isMobile && (
								<PageButton $disabled={isLoading || page === 1} onClick={() => setPage(1)}>
									<FirstIcon />
								</PageButton>
							)}
							<PageButton $disabled={isLoading || page === 1} onClick={() => setPage(page - 1)}>
								<ChevronLeftIcon />
							</PageButton>
							<PaginationSpacer />

							{isMobile ? (
								<PageSelect
									small
									onChange={e => setPage(Number(e.target.value))}
									value={page}
									values={Array(totalPages).fill(1).map((__, idx) => ({
										value: idx + 1,
										desc: `Page ${idx + 1} of ${totalPages}`,
									}))}
								/>
							) : (
								pageButtons.map((p, idx) => (
									<PageButton
										key={`page-${p || `${p}-${idx}`}`}
										$disabled={isLoading || page === p}
										$dots={p === null}
										$active={page === p}
										onClick={() => p && setPage(p)}
									>
										{p}
									</PageButton>
								))
							)}

							{/* First/Prev navigation buttons */}
							<PaginationSpacer />
							<PageButton $disabled={isLoading || page === totalPages} onClick={() => setPage(page + 1)}>
								<ChrevonRightIcon />
							</PageButton>
							{!isMobile && (
								<PageButton $disabled={isLoading || page === totalPages} onClick={() => setPage(totalPages)}>
									<LastIcon />
								</PageButton>
							)}
						</Pagination>
					)}
				</>
			)}
		</Container>
	);
};

export default Table;
