import { useSelector } from 'react-redux';

import { selectUser } from 'app/src/selectors/user';

export const selectPromptsList = state => (state.guardian.prompts ? state.guardian.prompts : {});
export const selectTestResult = state => (state.guardian.testResult ? state.guardian.testResult : {});
export const selectHasAccessToGuardian = state => {
	const user = selectUser(state);
	return [
		'daniel@poki.com',
		'erikd@poki.com',
		'ipek@poki.com',
	].includes(user.email);
};

export const useSelectPromptsList = () => useSelector(state => selectPromptsList(state));
export const useSelectTestResult = () => useSelector(state => selectTestResult(state));
export const useSelectHasAccessToGuardian = () => useSelector(state => selectHasAccessToGuardian(state));
