import * as additionalPaymentsEpics from 'app/src/epics/additionalPayments';
import * as dataEpics from 'app/src/epics/data';
import * as apiEpics from 'app/src/epics/api';
import * as clientEpics from 'app/src/epics/client';
import * as domainsEpics from 'app/src/epics/domains';
import * as invoiceEpics from 'app/src/epics/invoice';
import * as gameEpics from 'app/src/epics/game';
import * as effectsEpics from 'app/src/epics/effects';
import * as searchEpics from 'app/src/epics/search';
import * as sessionEpics from 'app/src/epics/session';
import * as teamEpics from 'app/src/epics/team';
import * as userEpics from 'app/src/epics/user';
import * as reviewEpics from 'app/src/epics/review';
import * as playtestEpics from 'app/src/epics/playtests';
import * as playerFitEpics from 'app/src/epics/playerfit';
import * as guardianEpics from 'app/src/epics/guardian';
import * as trackingEpics from 'app/src/epics/tracking';

import flatten from 'app/src/utils/flatten';

const epics = [
	...Object.values(additionalPaymentsEpics),
	...Object.values(apiEpics),
	...Object.values(clientEpics),
	...Object.values(dataEpics),
	...Object.values(domainsEpics),
	...Object.values(gameEpics),
	...Object.values(invoiceEpics),
	...Object.values(effectsEpics),
	...Object.values(searchEpics),
	...Object.values(sessionEpics),
	...Object.values(teamEpics),
	...Object.values(userEpics),
	...Object.values(reviewEpics),
	...Object.values(playtestEpics),
	...Object.values(playerFitEpics),
	...Object.values(guardianEpics),
	...Object.values(trackingEpics),
];

export default flatten(epics);
