import React, { createRef, useMemo } from 'react';
import styled from 'styled-components';

import FloatingCard from 'app/src/components/ui/FloatingCard';
import { useSelectPermissions } from 'app/src/selectors/user';
import checkPermissions from 'app/src/utils/checkPermissions';
import UserImage from 'app/src/components/ui/UserImage';
import { motionSpeed02, motionOut } from 'shared/vars';

const Container = styled(FloatingCard)`
	min-width: 240px;
`;

const SubMenuFloatingCard = styled(FloatingCard)`
	left: calc(100% + 24px);
	top: 50%;
	transform: translateY(-50%);
	max-height: 190px;
	min-width: 200px;
	opacity: 0;
	overflow: auto;
	transition: all ${motionSpeed02} ${motionOut};
	pointer-events: none;

	&:after {
		content: none;
	}
`;

const Row = styled.div`
	cursor: default;
	font-weight: normal;
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 24px;
	color: ${props => props.theme.grey3};
	margin: 8px 0;

	> svg {
		[fill] {
			fill: ${props => props.theme.grey3};
		}

		margin-right: 8px;
	}

	${props => (props.onClick || props.hasSubMenu) && `
	font-weight: bold;
	cursor: pointer;

	&:hover {
		color: ${props.theme.pokiBlue};
	}

	&:hover > svg {
		[fill] {
			fill: ${props.theme.pokiBlue};
		}
	}
	`}

	${props => props.hasSubMenu && `
	position: relative;
	&:before {
		${/* hitbox */''}
		background: transparent;
		content: '';
		position: absolute;
		right: -30px;
		width: 100px;
		height: 50px;
	}

	&:hover {
		${SubMenuFloatingCard} {
			opacity: 1;
			pointer-events: all;
		}
	}
	`}
`;

const StyledUserPicture = styled(UserImage)`
	width: 24px;
	height: 24px;
	margin-right: 14px;
`;

const FloatingMenu = props => {
	const { title = '', buttons, active, xDirection = 'right' } = props;

	const permissions = useSelectPermissions();

	const finalButtons = useMemo(() => (
		buttons.filter(button => checkPermissions(permissions, button.needsPermissions))
			.filter(button => button && (button.contents || button.name))
			.map(button => {
				if (!button.children) return button;

				// Make sure we have a least one child available, otherwise skip this section all together
				const availableChild = button.children.find(child => checkPermissions(permissions, child.needsPermissions));
				if (!availableChild) return null;

				return button;
			})
			.map(button => ({
				...button,
				id: `${title}-${button.name}`,
				ref: createRef(),
			}))
	), [buttons, permissions, title]);

	return (
		<Container
			active={active}
			xDirection={xDirection}
		>
			{finalButtons.map(button => (
				<Row
					key={button.id}
					onClick={button.onClick}
					ref={button.ref}
					hasSubMenu={!!button.children}
				>
					<button.icon /> {button.name} {button.contents ? <button.contents /> : null}
					{button.children && (
						<SubMenuFloatingCard active>
							{button?.children?.map(children => (
								<Row key={children.id || children.name} onClick={children.onClick}>
									{children.picture && <StyledUserPicture src={children.picture} />}
									{children.name}
									{children.contents ? <children.contents /> : null}
								</Row>
							))}
						</SubMenuFloatingCard>
					)}
				</Row>
			))}
		</Container>
	);
};

export default FloatingMenu;
