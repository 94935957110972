import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';

import { setGameChecklistStatus } from 'app/src/actions/session';
import { useSelectActiveTeam } from 'app/src/selectors/team';
import { getGameById } from 'app/src/epics/game';
import history from 'app/history';

import Button from 'app/src/components/ui/Button';
import SpinnerIcon from 'shared/components/svg/SpinnerIcon';

import { requiredPlaytestRecordingsWatched } from 'shared/vars';
import _ from 'shared/copy';

const Container = styled.div`
	background: ${props => props.theme.grey7};
	display: flex;
	width: 100%;
	max-width: 444px;
	padding: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
`;

const StyledHeader = styled.h4`
	font: 700 16px/20px "Proxima Nova";
	margin: 0;
`;

const StyledSpinAnimation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const StyledSpinnerIcon = styled(SpinnerIcon)`
	animation: ${StyledSpinAnimation} 1s linear infinite;
`;

const StyledFooterText = styled.div`
	color: ${props => props.theme.grey3};
	font: 400 14px/20px "Proxima Nova";
	margin: 16px 0 0;
`;

const Level2Step3 = ({ disabled, game }) => {
	const dispatch = useDispatch();
	const activeTeam = useSelectActiveTeam();

	const recordingsWatched = game.playtest_recordings_watched || 0;
	const isGameJam = activeTeam?.customer_segment === 'game-jam';

	// Fetch the up-to-date recordings watched count.
	useEffect(() => {
		if (!game?.id) {
			return;
		}

		dispatch(getGameById.fetch(game.id));
	}, [game?.id]);

	const onSeeResultsClick = () => {
		history.push(`/${game.team.code}/games/${game.id}/playtests`);
	};

	let watchMoreRecordingsCopy = recordingsWatched === 0 ? _`watchNRecordings${{ n: requiredPlaytestRecordingsWatched }}` : _`watchMoreRecordings${{ n: Math.max(0, requiredPlaytestRecordingsWatched - recordingsWatched) }}`;

	if (requiredPlaytestRecordingsWatched - recordingsWatched === 1) {
		// fix pluralization for 1 recording
		watchMoreRecordingsCopy = watchMoreRecordingsCopy.replace('recordings', 'recording');
	}

	const onSuccess = () => {
		dispatch(setGameChecklistStatus({ gameId: game.id, level: 'playtest', step: 3, status: 'completed' }));
	};

	return (
		<Container>
			<StyledHeader>
				{_`earlyTestBetter`}
			</StyledHeader>

			{_`learnFirstPlayer`}

			{isGameJam && (
				<Button onClick={onSeeResultsClick} disabled={disabled}>{_`startPlaytest`}</Button>
			)}
			{!isGameJam && recordingsWatched >= requiredPlaytestRecordingsWatched && (
				<Button onClick={onSuccess}>Go to the next level</Button>
			)}
			{!isGameJam && recordingsWatched < requiredPlaytestRecordingsWatched && (
				<div>
					{game.playtest_recordings_unwatched === 0 ? (
						<Button onClick={onSeeResultsClick} disabled={disabled}>{_`startPlaytest`}</Button>
					) : (
						<Button icon={StyledSpinnerIcon} onClick={onSeeResultsClick}>{_`seePlaytestResults`}</Button>
					)}
					<StyledFooterText>{watchMoreRecordingsCopy}</StyledFooterText>
				</div>
			)}
		</Container>
	);
};

export default Level2Step3;
