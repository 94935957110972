import { createAuthorizedApiEpic } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import dataFormatter from 'app/src/utils/dataFormatter';

export const getPlayerFitTestsByGameId = createAuthorizedApiEpic(
	'game/get_player_fit_tests_for_game',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/player_fit_tests`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const requestPlayerFitTest = createAuthorizedApiEpic(
	'game/request_player_fit_tests',
	(callApi, { gameId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/player_fit_tests`),
		method: 'POST',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'player_fit_tests',
				...data,
			},
		})),
	}),
);

export const stopPlayerFitTest = createAuthorizedApiEpic(
	'game/stop_player_fit_test',
	(callApi, { gameId, PlayerFitTesttId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/player_fit_tests/${PlayerFitTesttId}/@stop`),
		method: 'POST',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);
