import dataFormatter from 'app/src/utils/dataFormatter';
import { getPlayerFitTestsByGameId } from 'app/src/epics/playerfit';

const initialState = {
	tests: [],
};

export default function playerFitReducer(state = initialState, action) {
	if (action.type === getPlayerFitTestsByGameId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			tests: {
				...state.tests,

				[action.payload.options.gameId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	return state;
}
