import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { merge, of, Subject, switchMap, tap } from 'rxjs';
import { Controller, useForm } from 'react-hook-form';
import { useSelectApiStatus } from '@poki/rx-api';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import history from 'app/history';
import * as yup from 'yup';

import { registerModal } from 'app/src/modals';
import { openToast, uncaughtServerError } from 'app/src/actions/client';
import { startScreenShake } from 'app/src/actions/effects';
import { deleteGame } from 'app/src/epics/game';

import Modal, { EXIT_SOURCES } from 'app/src/components/ui/Modal';
import TextAreaInput from 'app/src/components/input/TextAreaInput';
import Button from 'app/src/components/ui/Button';

import _ from 'shared/copy';

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 20px;
	gap: 8px;
`;

const ConfirmRemoveGameList = styled.ul`
	padding: 0px 0px 0px 24px;
`;

const ConfirmRemoveGameItem = styled.li`
	margin-bottom: 8px;
`;

const validationSchema = yup.object().shape({
	reason: yup.string().required(_`fieldRequired`),
});

const ConfirmRemoveGameModal = ({ data }) => {
	const { gameId, teamCode } = data;

	const dispatch = useDispatch();

	const [exit$] = useState(new Subject());

	const deleteGameStatus = useSelectApiStatus(deleteGame.id);

	const { handleSubmit, control, formState: { errors, isDirty } } = useForm({
		defaultValues: { reason: '' },
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = formData => {
		const { reason } = formData;

		dispatch(deleteGame.fetch({ gameId, data: { reason } }, ({ success$, error$ }) => (
			merge(
				success$.pipe(
					tap(() => {
						exit$.next();
						history.push(`/${teamCode}`);
					}),
					switchMap(() => (
						of(openToast({ body: 'Game removed successfuly' }))
					)),
				),
				error$.pipe(
					tap(() => exit$.next()),
					switchMap(action => (
						of(startScreenShake(), uncaughtServerError({ action }))
					)),
				),
			)
		)));
	};

	return (
		<Modal
			exit$={exit$}
			canExit={source => (source === EXIT_SOURCES.SUBJECT || !deleteGameStatus.pending)}
		>
			<h2>{_`deleteGame`}</h2>
			<p>{_`confirmRemoveGame`}</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					control={control}
					name="reason"
					render={({ field: { onChange, value } }) => (
						<TextAreaInput
							label="Reason to delete game"
							small
							required
							value={value}
							valueSetter={onChange}
							resize={false}
							errors={errors?.reason?.message ? [errors?.reason?.message] : []}
							disabled={deleteGameStatus.pending}
						/>
					)}
				/>
				<p>{_`confirmRemoveGameDesc`}</p>
				<ConfirmRemoveGameList>
					<ConfirmRemoveGameItem>{_`confirmRemoveGameStep1`}</ConfirmRemoveGameItem>
					<ConfirmRemoveGameItem>{_`confirmRemoveGameStep2`}</ConfirmRemoveGameItem>
					<ConfirmRemoveGameItem>{_`confirmRemoveGameStep3`}</ConfirmRemoveGameItem>
				</ConfirmRemoveGameList>
				<Buttons>
					<Button onClick={() => exit$.next()} disabled={deleteGameStatus.pending} secondary>
						{_`cancel`}
					</Button>
					<Button submit negative disabled={deleteGameStatus.pending || !isDirty}>
						{deleteGameStatus.pending ? _`removing` : _`deleteGame`}
					</Button>
				</Buttons>
			</form>
		</Modal>
	);
};

registerModal('confirm-remove-game', ConfirmRemoveGameModal);
