import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useParams } from 'react-router-dom';

import useActionCounter from 'app/src/hooks/useActionCounter';
import { getTeamByCode, patchTeam } from 'app/src/epics/team';
import { useSelectTeamByCode } from 'app/src/selectors/team';

import GridContainer from 'app/src/components/ui/GridContainer';
import EmptyMessage from 'app/src/components/ui/EmptyMessage';
import Container from 'app/src/components/ui/Container';
import HeroTeam from 'app/src/components/HeroTeam';

import _ from 'shared/copy';

const HIDDEN_PAGES_NON_VERIFIED = ['billing', 'revenue-sharing', 'additional-payments'];

const TeamSettingsPage = props => {
	const { teamCode } = useParams();
	const { subComponent: SubComponent } = props;

	const dispatch = useDispatch();
	const location = useLocation();

	const [hidePage, setHidePage] = useState(false);

	const team = useSelectTeamByCode(teamCode);

	const page = location.pathname.split('/').pop();

	useEffect(() => {
		if (team && !team.verified) {
			setHidePage(HIDDEN_PAGES_NON_VERIFIED.includes(page));
		}
	}, [team, page]);

	const actionCounter = useActionCounter(patchTeam.success.type);

	useEffect(() => {
		dispatch(getTeamByCode.fetch(teamCode));
	}, [actionCounter]);

	return (
		<Container>
			{team && (
				<Helmet key={`TeamSettingsPage-${team.code}`}>
					<title>{`Settings - ${team.name} - Poki for Developers`}</title>
				</Helmet>
			)}
			<GridContainer cols={1}>
				<HeroTeam data={team} />
			</GridContainer>
			{team && !hidePage ? <SubComponent team={team} /> : (
				<EmptyMessage
					title={(
						<>
							{_`settingsPageNotAvailabe`}
							<Link to={`/${team.code}/settings`}>Verify the team.</Link>
						</>
					)}
				/>
			)}
		</Container>
	);
};

export default TeamSettingsPage;
